/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --popup-background: #0005;

    @include mobile {
        --popup-content-padding: 20px;
    }

    --popup-content-background: #{$white};
    --popup-min-width: 100%;
    --popup-location-min-width: 95%;
    --popup-content-padding: 30px 30px 35px;

    @include after-mobile {
        --popup-min-width: 428px;
        --popup-location-min-width: 428px;
    }

    --product-compare-success-popup-image: 108px;
}

.Popup {
    opacity: 0;
    pointer-events: none;
    z-index: 400;
    position: fixed;
    background-color: var(--popup-background);
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile {
        align-items: stretch;
    }

    @include print-only {
        display: inline-block !important;
        position: static !important;
        background-color: transparent !important; 
        width: 100% !important;
    }

    &_isVisible {
        pointer-events: all;
        opacity: 1;
        width: 100vw;
        height: 100vh;

        @include mobile {
            height: calc(100% - var(--header-total-height));
        }
    }

    &-Heading {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: $letter-spacing-s;
        text-align: center;
        margin-top: 0;
        margin-bottom: 20px;
    }

    &-Header {
        @include mobile {
            display: none;
        }
    }

    &-Container {
        @include mobile {
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }

    &-Content {
        background-color: var(--popup-content-background);
        border-radius: 5px;
        padding: var(--popup-content-padding);
        min-width: var(--popup-min-width);
        max-width: calc(var(--content-wrapper-width) * .8);
        max-height: 80%;
        max-height: 62.2vh;
        overflow-y: auto;

        @include desktop {
            @include custom-scroll;
            
            max-width: var(--popup-min-width);
        }

        @include mobile {
            border-radius: 0;
            max-height: 100%;
        }

        @include print-only {
            --popup-content-padding: 0;

            padding: 0;
            max-height: none !important;
            max-width: 100%;
            overflow: visible;
        }

        &::-webkit-scrollbar-track {
            @include desktop {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }

        .Field {
            &:first-of-type {
                margin-top: 0;
            }
        }
    }

    & &-CloseBtn {
        top: 25px;

        @include desktop {
            @include close-button-popup;

            background: var(--primary-base-color);
            height: 32px;
            width: 32px;
            position: absolute;
            top: -1rem;
            right: -1rem;
            z-index: 99;
            border-radius: 50%;
        }
    }

    .Loader {
        @include mobile {
            position: fixed;
        }
    }
}

.ProductCompareAddSuccessPopup {
    .Popup-Content {
        @include desktop {
            --popup-min-width: 388px;
        }
    }

    &-wrapper {
        text-align: center;

        @include mobile {
            padding-top: 80px;
        }
    }

    &-productImageContainer {
        text-align: center;

        img {
            max-width: var(--product-compare-success-popup-image);
        }
    }

    &-productNameContainer {
        @include paragraph-text;

        color: var(--content-paragraph-color);
        line-height: 2.5rem;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    &-productName {
        display: inline-block;
        text-transform: capitalize;
    }
}

.MyAccountCustomerPopup {
    .Popup {
        &-Heading {
            text-transform: capitalize;
        }

        &-Content {
            @include desktop {
                --popup-content-padding: 30px 30px 0;
            }

            @include mobile {
                --popup-content-padding: 20px 20px 0;
            }

            .Form {
                display: inline-block;
                width: 100%;

                @include desktop {
                    padding-bottom: 35px;
                }
    
                @include mobile {
                    padding-bottom: 20px;
                }
            }

            .Button {
                width: 100%;
            }
        }
    }
}

.MyAccountAddressPopup {
    .Popup {
        &-Content {
            @include desktop {
                --popup-content-padding: 30px 15px 0 30px;
            }

            @include mobile {
                --popup-content-padding: 20px 20px 0;
            }

            .Form {
                display: inline-block;
                width: 100%;

                @include desktop {
                    padding-bottom: 35px;
                }
    
                @include mobile {
                    padding-bottom: 20px;
                }

                .Button {
                    margin-bottom: 0;
                }
            }

            .Button {
                width: 100%;

                @include desktop {
                    margin-bottom: 35px;
                }

                @include mobile {
                    margin-bottom: 20px;
                }
            }
        }
    }

    .Field {
        &_type_text {
            @include first-and-last-single;

            &:nth-child(2) {
                margin-top: 0;
            }
        }
    }
}

.PageContentPopup {
    .Popup-Content {
        padding: 0;

        @include desktop {
            --popup-min-width: 665px;
        }

        .CmsPage {
            @include mobile {
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        .CmsPage-Wrapper {
            padding: 0;
        }

        .CmsPage-Container {
            margin: 0;
        }
    }
}

.scrollDisabled {
    position: fixed;
    margin-top: 0;
    width: 100%;
    overflow-y: scroll;

    @include print-only {
        position: static;
    }
}
