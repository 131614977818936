/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.Breadcrumb {
    $crumb-padding: 10px;
    $arrow-size: 5px;

    @include paragraph-text;

    display: inline-block;
    padding: 0 $crumb-padding 0 0;

    &:not(:last-child) {
        margin: 0;

        &::after {
            content: '';
            position: relative;
            display: inline-block;
            height: $arrow-size;
            width: $arrow-size;
            transform: rotate(-135deg);
            margin-left: $crumb-padding - 4;
            border-color: currentColor;
            border-style: solid;
            border-width: 0 0 1px 1px;
            top: -1px;
        }
    }

    &:last-child {
        --breadcrumbs-color: var(--breadcrumbs-active-color);

        pointer-events: none;
        margin: 0;
    }

    &::before {
        content: none;
    }

    &-Link {
        color: var(--breadcrumbs-color);
        display: inline-block;
    }
}
