/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
:root {
  --flash-sale-timer-unit-width: 26px;
  --flash-sale-timer-unit-height: 26px;
  --flash-sale-timer-unit-background: #{$white};
  --flash-sale-timer-unit-border: 1px solid #{$grey13};
}

.FlashSaleTimer {
  .FlashSaleTimer {
    &-Units {
      display: flex;
      justify-content: center;
    }

    &-Unit {
      display: flex;
      align-items: flex-start;
      font-size: 1rem;
      line-height: 1.1rem;
      padding-right: 3px;

      &:last-of-type {
        padding-right: 0;
      }

      > div {
        width: var(--flash-sale-timer-unit-width);
      }

      span {
        background-color: var(--flash-sale-timer-unit-background);
        color: $red2;
        font-size: 1.4rem;
        font-weight: $font-weight-bold;
        line-height: var(--flash-sale-timer-unit-height);
        width: var(--flash-sale-timer-unit-width);
        height: var(--flash-sale-timer-unit-height);
        border: var(--flash-sale-timer-unit-border);
        display: block;
        margin-bottom: 3px;
        border-radius: 3px;
        text-align: center;
        letter-spacing: 0;
      }

      p {
        padding-left: 3.5px;
        color: $red2;
        font-size: 1.4rem;
        line-height: 2.5rem;
      }
    }

    &-ProgressBarContainer {
      font-size: 1.2rem;
      line-height: 1.4rem;
      letter-spacing: 0;

      @include mobile {
        max-width: 81px;
        width: 100%;
      }
    }

    &-LeftQuantityUpperBar {
      height: .5rem;
      background-color: $grey24;
      border-radius: .6rem;
      overflow: hidden;
      margin-bottom: 6px;

      @include mobile {
        width: 100%;
      }

      @include desktop-xl {
        width: 12.4rem;
      }
    }

    &-LeftQuantityInnerBar {
      height: .5rem;

      &_green {
        background-color: $green1;
      }

      &_orange {
        background-color: var(--primary-base-color);
      }

      &_red {
        background-color: var(--primary-light-color);
      }
    }
  }

  &.FlashSaleTimer_PDP {
    order: 5;
    flex-basis: 100%;
    padding-bottom: 20px;

    @include mobile {
      order: -3;
    }

    .FlashSaleTimer {
      &-Icon {
        display: flex;
      }

      &-Inner {
        display: flex;
        align-items: center;
        background: $grey25;
        padding: 10px;

        @include desktop {
          border-radius: 5px;
          width: fit-content;
        }
      }

      &-Title {
        padding-left: 1rem;
        padding-right: 1rem;

        span {
          font-size: 1.2rem;
          line-height: 1.4rem;
          display: block;

          &:first-child {
            font-weight: $font-weight-bold;
            text-transform: uppercase;
          }
        }
      }

      &-Unit {
        @include mobile {
          padding-right: 2px;
        }

        p {
          @include mobile {
            padding-left: 1.5px;
          }
        }
      }

      &-ProgressBarContainer {
        padding-left: 10px;

        @include mobile {
          padding-left: 8px;
        }
      }
    }
  }

  &.FlashSaleTimer_CartPage {
    padding-right: 1rem;

    @include mobile {
      margin-bottom: 5px;
    }

    .FlashSaleTimer {
      &-Inner {
        display: flex;
        flex-wrap: wrap;
      }

      &-Title {
        width: 100%;
        font-size: 1.2rem;
        line-height: 1.4rem;
        margin-bottom: 5px;

        span {
          &:first-of-type {
            font-weight: $font-weight-bold;
          }
        }
      }

      &-ProgressBarContainer,
      &-Icon {
        display: none;
      }
    }
  }

  &.FlashSaleTimer_PLP {
    padding: 10px 0 0;

    .FlashSaleTimer {
      &-Inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }

      &-Title {
        width: 100%;
        font-size: 1.2rem;
        text-align: center;
        margin-bottom: 5px;
        order: 1;

        span {
          &:first-of-type {
            font-weight: $font-weight-bold;
          }
        }
      }

      &-Units {
        order: 2;
      }

      &-Unit {
        @include mobile {
          padding-right: 1.5px;
        }

        @include desktop {
          padding-right: 2px;
        }

        &:last-of-type {
          padding-right: 0;

          p {
            padding: 0;
          }
        }

        p {
          @include mobile {
            padding: 0 3px;
          }

          @include desktop {
            padding: 0 5px;
          }
        }
      }

      &-ProgressBarContainer {
        margin-bottom: 1rem;
        text-align: left;
        order: 0;

        @include mobile {
          max-width: fit-content;
        }
      }

      &-LeftQuantityUpperBar {
        width: 15rem;

        @include mobile {
          width: 14rem;
        }
      }

      &-Icon {
        display: none;
      }
    }
  }

  &.FlashSaleTimer_CHECKOUT_CART_ITEM {
    @include desktop {
      padding: 5px 0;
    }

    .FlashSaleTimer {
      &-Inner {
        display: flex;
        flex-wrap: wrap;
      }

      &-Title {
        width: 100%;
        font-size: 1.2rem;
        line-height: 1.4rem;
        margin-bottom: 5px;

        span {
          &:first-of-type {
            font-weight: $font-weight-bold;
          }
        }
      }

      &-ProgressBarContainer,
      &-Icon {
        display: none;
      }
    }
  }
}
