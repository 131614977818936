/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

 @mixin fixed-width {
    width: 100%;
    padding: 0 5px;
    margin-left: auto;
    margin-right: auto;

    @include desktop {
        max-width: $layout__max-width;
        padding: 0 10px;
    }
}

@mixin animation {
    transition-timing-function: ease-out;
    transition-duration: .25s;
}

@mixin h2 {
    margin-bottom: 30px;
    color: $h2-font-color;
    font-size: $h2-font-size-desktop;
    line-height: $h2-font-line-height-desktop;
    font-weight: $h2-font-weight;
    letter-spacing: $h2-letter-spacing;

    @include mobile {
        font-size: $h2-font-size-mobile;
        line-height: $h2-font-line-height-mobile;
        margin-bottom: 20px;
    }
}

@mixin h3 {
    margin-bottom: 30px;
    color: $h3-font-color;
    font-size: $h3-font-size-desktop;
    line-height: $h3-font-line-height-desktop;
    font-weight: $h3-font-weight;
    letter-spacing: $h3-letter-spacing;

    @include mobile {
        font-size: $h3-font-size-mobile;
        line-height: $h3-font-line-height-mobile;
        margin-bottom: 20px;
    }
}

@mixin h4 {
    margin-bottom: 30px;
    color: $h4-font-color;
    font-size: $h4-font-size-desktop;
    line-height: $h4-font-line-height-desktop;
    font-weight: $h4-font-weight;
    letter-spacing: $h4-letter-spacing;

    @include mobile {
        font-size: $h4-font-size-mobile;
        line-height: $h4-font-line-height-mobile;
        margin-bottom: 20px;
    }
}

@mixin h5 {
    margin-bottom: 30px;
    color: $h5-font-color;
    font-size: $h5-font-size-desktop;
    line-height: $h5-font-line-height-desktop;
    font-weight: $h5-font-weight;
    letter-spacing: $h5-letter-spacing;

    @include mobile {
        font-size: $h5-font-size-mobile;
        line-height: $h5-font-line-height-mobile;
        margin-bottom: 20px;
    }
}

@mixin h6 {
    margin-bottom: 30px;
    color: $h6-font-color;
    font-size: $h6-font-size-desktop;
    line-height: $h6-font-line-height-desktop;
    font-weight: $h6-font-weight;
    letter-spacing: $h6-letter-spacing;

    @include mobile {
        font-size: $h6-font-size-mobile;
        line-height: $h6-font-line-height-mobile;
        margin-bottom: 20px;
    }
}

@mixin sub-heading-1 {
    color: $subtitle-primary-font-color;
    letter-spacing: $subtitle-primary-letter-spacing;
    font-weight: $subtitle-primary-font-weight;
    font-size: $subtitle-primary-font-size;
    line-height: $subtitle-primary-font-line-height;
    margin: $subtitle-primary-margin;
}

@mixin sub-heading-2 {
    color: $subtitle-secondary-font-color;
    letter-spacing: $subtitle-secondary-letter-spacing;
    font-weight: $subtitle-secondary-font-weight;
    font-size: $subtitle-secondary-font-size;
    line-height: $subtitle-secondary-font-line-height;
    margin: $subtitle-secondary-margin;
}

@mixin paragraph-text {
    font-family: $paragraph-font-family;
    font-weight: $paragraph-font-weight;
    color: $paragraph-font-color;
    letter-spacing: $paragraph-letter-spacing;
    font-size: $paragraph-font-size-desktop;
    line-height: $paragraph-font-line-height-desktop;
}

@mixin icon($content, $position: before, $size: 2rem, $color: $default-primary-dark-color) {
    @if $position == before {
        &:before {
            font-family: $icomoon-font-family;
            content: $content;
            font-size: $size;
            line-height: $size;
            color: $color;
            font-weight: 400;
            transition: all .2s;
        }
    }
    @else {
        &:after {
            font-family: $icomoon-font-family;
            content: $content;
            font-size: $size;
            line-height: $size;
            color: $color;
            font-weight: 400;
            transition: all .2s;
        }
    }
}

@mixin custom-scroll {
    /* width */
    &::-webkit-scrollbar {
        width: 20px;
    }
  
    /* Track */
    &::-webkit-scrollbar-track {
        background: #{$grey15};
    }
   
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #{$grey16}; 
        border-radius: 25px;
        border: 6px solid #{$grey15};
    }
}

@mixin custom-radio-button {
    &::after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 0;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 1px solid #{$grey7};
        transition-timing-function: ease-out;
        transition-duration: .25s;
    }

    &_isSelected {
        &::after {
            border: 5px solid var(--primary-base-color);
            width: 8px;
            height: 8px;
            top: -1px;
        }
    }

    &:not(&_isSelected):hover {
        &::after {
            border: 1px solid var(--primary-dark-color);
            width: 16px;
            height: 16px;
        }
    }
}

@mixin first-and-last-single {
    &:nth-child(1),
    &:nth-child(2) {
        width: 48%;
        float: left;
        margin-bottom: 2rem;

        @include mobile {
            width: 48.5%;
            display: inline-block;
        }
    }

    &:nth-child(1) {
        margin-right: 3%;

        @include desktop {
            margin-right: 4%;
        }
    }

    &:nth-child(3) {
        clear: both;
    }
}