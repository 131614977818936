/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Yehan Virantha <yviranta@kemana.com>
 * http://www.kemana.com
 *
 */
 :root {
    --unit-sale-points-container-width: 798px;
}

.kemanapwa-home-page-usp {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 10px;
    background: $white;
    height: 130px;

     @include mobile {
        padding: 6px 5px 10px;
     }

     @media (min-width: 375px) {
         height: 120px;
     }

     @media (min-width: 600px) {
        height: 101px;
     }

     @include desktop {
        height: 70px;
     }

     .widget.block {
         width: 100%;
     }

    .unit-sale-points {
        max-width: var(--unit-sale-points-container-width);
        margin: auto;
        display: flex;
        flex-direction: row;
    }

    .sale-point {
        width: 33.33%;
        display: flex;
        align-items: center;
        padding: 0 10px;

        @include mobile {
            flex-direction: column;
            padding: 0 5px;
        }
    }

    .image-container {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;

        .Image {
            padding-bottom: 0;
            height: 50px;

            img {
                position: static;
            }
        }
    }

    .content-container {
        padding-left: 10px;
        width: calc(100% - 50px);

        @include mobile {
            width: 100%;
            text-align: center;
            padding: 5px 0 0;
        }
    }

    .point-title {
        text-transform: uppercase;
        font-weight: $font-weight-black;
        letter-spacing: $letter-spacing-m;
        line-height: 1.9rem;
        color: $default-primary-dark-color;

        @include mobile {
            font-size: 1.1rem;
            line-height: 1.5rem;
        }
    }

    .point-content {
        @include paragraph-text;

        @include mobile {
            font-size: 1.1rem;
            line-height: 1.5rem;
        }
    }
}
