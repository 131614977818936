/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

:root {
    --ProductSale-label-color: #{$white};
    --ProductSale-label-font-weight: #{$font-weight-black};
}

.ProductSale {
    &-Label {
        position: absolute;
        width: 82px;
        height: 25px;
        background-image: url(background/badge-background.svg);
        color: var(--ProductSale-label-color);
        font-weight: var(--ProductSale-label-font-weight);
        letter-spacing: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        &_displayPosition {
            &_top-left {
                left: -15px;
            }

            &_top-center {
                // TODO set the styles for this position
            }
  
            &_top-right {
                right: -15px;
            }
  
            &_bottom-left {
                left: -15px;
                bottom: 0;
            }
  
            &_bottom-center {
                bottom: 0;
            }
  
            &_bottom-right {
                right: -15px;
                bottom: 0;
            }
        }
    }
}

// PDP only styles 
.ProductActions {
    .ProductSale {
        &-Label {
            position: static;
            margin-bottom: 1rem;

            @include desktop {
                margin-top: -1rem;
            }

            @include mobile {
                order: -4;
            }
        }
    }
}