/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --notification-color: var(--secondary-info-color);
    --notification-background: #{$white};
    --notification-border-color: #{$white};
    --notification-box-shadow: #{$box-shadow3};
}

.Notification {
    --animation-duration: 0; /* to sync CSS animation and JS callback */

    will-change: max-height, opacity, transform, padding, margin;
    overflow: hidden;
    max-width: 100vw;
    position: relative;
    padding-top: 15px;
    padding-bottom: 14px;
    padding-left: 2rem;
    padding-right: 2rem;
    top: 0;
    color: var(--notification-color);
    background: var(--notification-background);
    border: 1px solid var(--notification-border-color);
    box-shadow: var(--notification-box-shadow);

    @include after-mobile {
        display: inline-block;
        max-width: 550px;
        margin-bottom: 20px;
        min-width: 352px;
        border-radius: 5px;
        margin-bottom: 5px;
        margin-top: 14px;
    }

    &-Text {
        margin-top: 0;
        font-size: 1.5rem;
        font-weight: $font-weight-regular;
        letter-spacing: $letter-spacing-m2;
        margin-right: 40px;
        margin-bottom: 0;
        color: inherit;

        @include mobile {
            margin-right: 15px;
        }
    }

    &-Debug {
        white-space: pre-wrap;
        background: white;
        color: black;
        padding: 5px;
        max-height: 150px;
        overflow: scroll;
    }

    &-Button {
        cursor: pointer;
        font-size: 0;
        appearance: none;
        background: none;
        position: absolute;
        right: 0;
        top: 0;
        padding: 0;
        width: 50px;
        height: 50px;
        opacity: 1;

        @include mobile {
            margin: auto;
            bottom: 0;
            right: 0;
        }

        &:focus {
            outline: none;
        }

        &::before,
        &::after {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            content: ' ';
            height: 20px;
            width: 2px;
            background-color: var(--notification-color);

            @include mobile {
                height: 16px;
            }
        }

        &::before {
            transform: rotate(45deg);
        }

        &::after {
            transform: rotate(-45deg);
        }
    }

    &_is {
        &_opening {
            animation: fadeInLeft var(--animation-duration) cubic-bezier(.645, .045, .355, 1) forwards;
        }

        &_closing {
            animation: fadeOutLeft var(--animation-duration) cubic-bezier(.645, .045, .355, 1) forwards;
        }
    }

    &_type {
        &_error {
            --notification-color: var(--primary-error-color);
            --notification-border-color: var(--primary-error-color);
            --notification-box-shadow: none;
        }

        &_success {
            --notification-color: #{$white};
            --notification-background: var(--primary-success-color);
            --notification-border-color: var(--primary-success-color);
            --notification-box-shadow: none;
        }

        &_info {
            --notification-color: var(--primary-info-color);
        }
    }

    @keyframes fadeInLeft {
        from {
            opacity: 0;
            transform: translateY(-300px);
        }

        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes fadeOutLeft {
        from {
            opacity: 1;
            transform: translateY(0);
            max-height: 100px;
            margin-bottom: 5px;

            @include mobile {
                margin-bottom: 20px;
            }
        }

        to {
            opacity: 0;
            transform: translateY(-300px);
            max-height: 0;
            padding: 0;
            margin: 0;
        }
    }
}
