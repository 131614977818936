/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
.MyAccountDeleteAccountPopup {
    .Popup-Content {
        @include desktop {
            --popup-min-width: 518px;
        }

        .Popup-Header {
            @include desktop {
                padding: 0 40px;
            }    
        }
    }

    &-Content {
        margin-bottom: 20px;
    }

    &-ActionButtons {
        display: flex;
        flex-wrap: wrap;

        @include desktop {
            justify-content: center;
        }
    }

    &-Delete {
        @include mobile {
            width: 100%;
            order: 1;
        }

        @include desktop {
            margin-left: 20px;
        }
    }

    &-Cancel {
        @include mobile {
            width: 100%;
            margin-top: 20px;
            order: 2;
        }
    }
}
