/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --option-background-color: #{$white};
    --option-check-mark-background: var(--primary-dark-color);
    --option-padding: 0 12px;
    --option-margin: 0;
    --option-size: 33px;
    --option-text-color: var(--primary-dark-color);
    --option-border-color: #{$grey7};
}

.ProductAttributeValue {
    --button-background: initial;
    --button-color: initial;
    --button-padding: 0;
    --option-is-selected: 0;

    margin-left: 0;

    &-Color,
    &-String,
    &-Image,
    &-Text {
        background-color: var(--option-background-color);
        border-color: var(--option-border-color);
        border-width: 1px;
        color: var(--option-text-color);
        display: inline-block;
        min-height: var(--option-size);
        width: var(--option-size);
        margin: var(--option-margin);
        padding: var(--option-padding);
    }

    &-Image {
        padding: 0;
        border-radius: 50%;
        position: relative;
    }

    &-Image-Overlay {
        --option-check-mark-background: white;

        position: absolute;
        top: 0;
        left: 0;
        margin-left: .6rem;

        @include mobile {
            margin-left: .7rem;
        }

        @include after-mobile {
            margin: 0;
        }
    }

    &-Color {
        margin-bottom: 0;
    }

    &-Color,
    &-Image-Overlay {
        border-radius: 50%;
        font-size: 0;
        width: var(--option-size);

        box-shadow: inset 0 0 0 1px var(--option-border-color);
        will-change: box-shadow, background-color;

        &::before,
        &::after {
            content: '';
            position: absolute;

            opacity: var(--option-is-selected);
            transition: opacity 200ms;
            will-change: opacity, border-left, border-right;
        }

        &::before {
            height: calc(var(--option-size) + 6px);
            width: calc(var(--option-size) + 6px);

            border-radius: inherit;
            box-shadow: inherit;
            left: -3px;
            top: -3px;
        }

        &::after {
            height: calc(var(--option-size) / 5);
            left: calc(var(--option-size) / 3.3);
            top: calc(var(--option-size) / 3.3);
            width: calc(var(--option-size) / 2.5);

            transform: rotate(-45deg);

            border: {
                left: 2px solid var(--option-check-mark-background);
                bottom: 2px solid var(--option-check-mark-background);
            }
        }

        &:hover {
            @include after-mobile {
                &::before,
                &::after {
                    --option-is-selected: .5;
                }
            }
        }
    }

    &-String,
    &-Text {
        border-style: solid;
        line-height: var(--option-size);
        min-width: calc(1.25 * var(--option-size));
        width: auto;
        text-align: center;

        &_isSelected {
            --option-border-color: var(--primary-dark-color);
            --option-text-color: var(--primary-dark-color);
        }
    }

    &-Text {
        display: flex;
        border: 0;
        align-items: flex-start;
        margin: 0;
        line-height: 1.9rem;
        padding: 0 0 2rem;

        &_isSelected {
            color: var(--primary-dark-color);
        }

        label {
            font-weight: 400;
            cursor: pointer;
            margin: 0;
            text-transform: capitalize;
            padding-left: 2.8rem;

            [type='checkbox'] {
                + .input-control {
                    position: absolute;
                    top: 0;
                    left: 0;
                    margin-left: 0;
                }
            }

            &:last-child {
                border-color: var(--option-border-color);
            }

            &:first-of-type {
                padding-bottom: 0;
                order: 1;
                text-align: left;

                overflow: hidden;
                text-overflow: ellipsis;

                ~ label {
                    margin: 0;
                    min-width: 1.8rem;
                }
            }
        }

        &:hover,
        &:focus {
            label {
                .input-control {
                    border-color: var(--primary-dark-color);

                    &::after {
                        @include desktop-l {
                            --checkmark-color: var(--option-check-mark-background);
                        }
                    }
                }
            }
        }
    }

    &:hover,
    &:focus {
        @include desktop {
            --option-border-color: #{$black};
            --option-text-color: #{$black};
        }

        text-decoration: none;
    }

    &_isNotAvailable {
        opacity: .25;
        pointer-events: none;
    }

    // &:last-of-type {
    //     .ProductAttributeValue-Text {
    //         padding-bottom: 0;
    //     }
    // }
}
