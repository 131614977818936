/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
:root {
    --menu-desktop-height: 3.5rem;
    --menu-background-mobile: #{$white};
    --menu-background-desktop: #{$default-primary-base-color};
    --menu-parent-item-color: #{$white};
    --menu-parent-item-padding: 0 2.5rem;
    --menu-first-child-item-color: #{$default-primary-dark-color};
    --menu-child-item-color: var(--body-paragraph-color);
    --menu-child-item-hover-color: var(--menu-first-child-item-color);
    --menu-mobile-border-bottom: var(--secondary-divider-color);
    --menu-mobile-item-height: 5rem;
    --menu-desktop-item-height: 3.5rem;
    --menu-item-height: 3.5rem;
}

%menu-items-common {
    @include before-desktop-l {
        margin-top: 0;
        margin-bottom: 0;
        height: var(--menu-mobile-item-height);
    }
}

.Menu {
    &-Link,
    &-SubCatLink {
        display: block;
        width: 100%;
        cursor: pointer;

        @include mobile {
            position: inherit;
        }
    }

    &-ItemList {
        display: block;

        @include mobile {
            position: static;
        }

        @include desktop-l {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        &_type_subcategory {
            @include before-desktop-l {
                padding: 0 2rem;
                height: calc(100vh - 110px);
                padding-bottom: 113px;
                overflow-y: auto;
                width: 100%;
            }

            @include desktop-l {
                justify-content: normal;
            }

            .Menu-SubItemWrapper {
                @include desktop {
                    width: 16.6%;
                    padding-right: 1rem;
                }
            }
        }
    }

    &-Item {
        padding: 0;
        @extend %menu-items-common;

        @include mobile {
            position: static;
        }

        &Caption_type {
            &_main {
                font-size: 1.5rem;
                font-weight: bold;
                line-height: var(--menu-desktop-item-height);
                color: var(--menu-first-child-item-color);

                @include before-desktop-l {
                    line-height: var(--menu-mobile-item-height);
                }

                @include desktop-l {
                    color: var(--menu-parent-item-color);
                    font-size: 1.3rem;

                    &:hover {
                        text-decoration: underline;

                        @include touchdevice {
                            text-decoration: none;
                        }
                    }
                }

                @include desktop-xl {
                    font-size: 1.5rem;
                }
            }

            &_subcategory {
                @include before-desktop-l {
                    font-size: 1.4rem;
                }
            }
        }

        &Figure_type_main {
            text-transform: none;
            background-color: transparent;

            @include before-desktop-l {
                z-index: -1;
            }

            @include before-desktop-l {
                display: block;
                grid-template-columns: auto;
                height: auto;
                padding-left: 0;
            }
        }

        &:last-child {
            padding-right: 0;

            @include desktop {
                display: none;
            }

            .Menu-Link {
                display: none;

                &_isSignedIn {
                    display: inline-block;
                }
            }
        }

        &List {
            &_type_main {
                width: unset;
            }
        }

        &Figure {
            @include before-desktop-l {
                border-bottom: 1px solid var(--menu-mobile-border-bottom);
                height: var(--menu-mobile-item-height);

                .Menu-ItemCaption {
                    font-size: 1.5rem;
                    color: var(--menu-first-child-item-color);
                    font-weight: $font-weight-bold;
                }
            }
        }

        .Menu-SubMenuMobileButton {
            @include menu-right-icon;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 25px;
            height: 50px;
            position: absolute;
            right: 0;
            top: 0;
            display: flex;
            justify-content: flex-end;

            &:after {
                padding-right: 0.4rem;
            }

            > span {
                display: none;
            }
        }
    }

    &-Link {
        @include before-desktop {
            padding: 0;
        }

        .Menu {
            &-ItemFigure {
                &:after {
                    display: none;
                }
            }
        }
    }

    &-SubCatLink {
        .Menu-ItemFigure {
            @include before-desktop-l {
                @include menu-right-icon;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                position: inherit;
                align-content: center;
                flex-wrap: wrap;

                &:after {
                    padding-right: 0.4rem;
                    font-size: 1.2rem;
                    line-height: 1.2rem;
                }
            }
        }
    }

    &-SubCategoriesWrapper {
        top: var(--menu-desktop-height);
    }

    &-SubItemWrapper {
        @include desktop {
            margin-right: 0;
            margin-bottom: 0;
        }

        @include desktop-l {
            margin-bottom: 3rem;
        }

        > .Menu-Link {
            @include desktop {
                text-transform: none;
            }
        }
    }

    &-SubLevelDesktop {
        .Menu {
            &-ItemList {
                color: var(--menu-child-item-color);
                text-transform: capitalize;
                margin-top: 1.5rem;
            }

            &-Link {
                margin-top: 0;
                line-height: 2.5rem;
                white-space: normal;

                &:hover {
                    color: var(--menu-child-item-hover-color);

                    @include touchdevice {
                        color: var(--menu-child-item-color);
                    }
                }
            }
        }
    }

    &-SubItemWrapper {
        > .Menu-Link {
            padding: 0;
            white-space: normal;
            line-height: 1.9rem;
            @extend %menu-items-common;

            .Menu-ItemCaption {
                @include before-desktop-l {
                    white-space: inherit;
                    line-height: 1.9rem;
                }

                @include desktop-l {
                    text-transform: uppercase;
                    color: var(--menu-first-child-item-color);
                    font-weight: 700;
                }
            }
        }
    }

    &-Main {
        &Categories {
            z-index: 1;

            @include mobile {
                display: block;
                width: 100%;
                position: static;
            }

            @include desktop {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
    }

    &-Menu {
        &Wrapper {
            margin: 0;
            min-height: 35px;
            background-color: var(--menu-background-mobile);

            @include before-desktop-l {
                padding-top: 0;
                padding-bottom: 0;
                padding: 0 2rem;
                position: static;
            }

            @include desktop-l {
                background-color: var(--menu-background-desktop);
            }

            @include print-only {
                display: none;
            }
        }
    }

    &-SubMenu {
        --overlay-background: #{$white};

        @include before-desktop-l {
            height: 100%;
            left: 0;
            padding-top: 55px;
            padding-bottom: 55px;
            position: fixed;
            top: 0;
            width: 100%;
            background: $white;
            overflow-y: hidden;
        }
    }
}

main {
    &.MenuPage {
        @include before-desktop-l {
            margin-top: 7rem;
            position: static;
        }

        &:before {
            background: $white;
        }
    }
}

a[href="/track-order/"] {
    @include desktop {
        display: none;
    }
}