/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --footer-height: calc(var(--footer-content-height) + var(--footer-copyright-height));
    --footer-content-height: 320px;
    --footer-copyright-height: 41px;
    --footer-top-section-background: #{$green4};
    --footer-top-section-max-width: 1135px;

    --footer-copyright-color: #{$white};

    --footer-social-icon-width: 43px;
    --footer-social-icon-hover: #{$white};
}

.Footer {
    $column-count: 4;

    @include mobile {
        margin-bottom: var(--navigation-tabs-height);
    }

    @include print-only {
        display: none;
    }

    &-CopyrightContentWrapper {
        background-color: var(--footer-top-section-background);
    }

    &-CopyrightContent {
        padding: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: var(--footer-copyright-height);
    }

    &-Copyright {
        color: var(--footer-copyright-color);
        text-align: center;

        a {
            color: inherit;
        }
    }

    &-Content {
        background-color: var(--footer-top-section-background);

        .SubTitle-Secondary {
            color: $white;
        }
    }

    &-Columns {
        display: flex;
        justify-content: space-between;
        height: 100%;
        padding: 24px 20px 18px;

        @include mobile {
            flex-direction: column;
            align-items: center;
            text-align: center;
        }

        @include after-mobile {
            padding: 39px 20px 88px;
            max-width: var(--footer-top-section-max-width);
        }

        @include tablet {
            padding: {
                left: 30px;
                right: 30px;
            };
        }
    }

    &-Column {
        width: 100%;

        @include desktop {
            width: calc(100% - 38%);
        }

        &:nth-child(2) {
            @include desktop {
                width: 39.4%;
                max-width: 423px;
            }
        }
    }

    &-ColumnTitle {
        text-transform: uppercase;
        margin: 0 0 15px;
        font-size: 2rem;
        display: none;

        @include after-mobile {
            margin: 0 0 20px;
        }
    }

    &-ColumnContent {
        display: flex;
        flex-direction: column;

        &_direction_horizontal {
            flex-direction: row;

            @include mobile {
                justify-content: center;
            }
        }

        a + a {
            margin-left: 0;
        }
    }

    &-ColumnItem {
        color: inherit;
        margin-bottom: 12px;
        font-size: 1.6rem;

        &:last-of-type {
            margin: 0;
        }

        &_type_image {
            width: 25px;

            &:not(:last-of-type) {
                margin-right: 20px;
            }
        }
    }

    &-CmsBlockWrapper {
        .CmsBlock-Wrapper {
            flex: 1;
        }

        .ContentWrapper .Footer-Columns {
            padding: 0;
        }

        .Footer-Column_isNewsletter {
            @include desktop {
                padding-left: 100px;
            }
        }
    }

    &-FooterLeftContent {
        .CmsBlock-Wrapper {
            display: flex;
        }

        .Footer-Content {
            width: 100%;

            @include desktop {
                display: flex;
            }
        }

        .Section {
            width: 100%;
            text-align: left;

            @include mobile {
                position: relative;
            }

            details {
                summary:after {
                    color: $white;
                }
            }

            &:nth-child(4) {
                details {
                    summary {
                        border-top: 0;
                    }
                }
            }

            @include desktop {
                padding-right: 20px;
                max-width: 224px;
            }

            &.Links-Container {
                .Button_likeLink {
                    --button-like-link-secondary-color: #{$white};
                    --button-like-link-secondary-hover-color: #{$white};
                    --button-like-link-secondary-hover-text-decoration: underline;

                    line-height: 20px;
                    margin-bottom: 10px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .Button_likeLink {
                width: 100%;
            }
        }

        .Customer-Service {
            margin-bottom: 20px;

            .SubTitle-Secondary {
                margin-bottom: 10px;
            }

            p {
                color: #{$white};
                line-height: 2rem;
            }
        }

        .Customer-Email {
            position: relative;
            padding-left: 0;
             display: flex;
            justify-content: center;
            margin-bottom: 13px;

            @include mobile {
                display: inline-block;
            }

            @include customer-email-icon;

            &:before {
                margin-right: 10px;
                margin-top: 0;
                color: #{$white};

                @include mobile {
                    display: inline-block;
                }
            }

            .Button_likeLink_Primary {
                font-size: 14px;
                letter-spacing: #{$letter-spacing-m};

                --button-like-link-primary-color: #{$white};
                --button-like-link-primary-hover-color: #{$white};
                --button-like-link-primary-hover-text-decoration: underline;

                @include mobile {
                    width: auto;
                    display: inline-block;
                }
            }
        }

        .Customer-Number {
            position: relative;
            padding-left: 27px;
            line-height: 20px;

            @include customer-number-icon;

            &:before {
                display: block;
                position: absolute;
                left: 1px;
                top: 3px;
            }

            &-Title {
                font-weight: #{$font-weight-bold};
            }

            .Button_likeLink_Primary {
                font-size: 14px;
                letter-spacing: #{$letter-spacing-m};

                --button-like-link-primary-hover-color: var(--primary-base-color);
                --button-like-link-primary-hover-text-decoration: none;
            }
        }
    }

    &-FooterRightContent {
        @include mobile {
            margin-top: 25px;
        }

        > .SubTitle-Secondary {
            display: none;
        }

        .NewsletterSubscription {
            display: none;
        }

        .SubTitle-Secondary {
            margin-bottom: 15px;
        }

        .Payment-Option {
            margin-bottom: 30px;

            @include mobile {
                padding: 0 30px;
            }

            @include desktop {
                margin-bottom: 40px;
            }

            .Image {
                width: 78px;
                padding-bottom: 41px;
                margin-right: 10px;

                @include desktop {
                    width: 59px;
                    padding-bottom: 31px;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .Social-Media {
            &-Icons {
                display: flex;

                @include mobile {
                    justify-content: center;
                }

                > a {
                    &:last-of-type {
                        margin-right: 0;
                    }
                }

                .Facebook,
                .Instagram,
                .Youtube,
                .Tiktok {
                    width: var(--footer-social-icon-width);
                    height: var(--footer-social-icon-width);
                    border-radius: 50%;
                    text-align: center;
                    margin-right: 20px;
                    text-decoration: none;

                    &:hover {
                        @include desktop {
                            background-color: var(--footer-social-icon-hover);
                        }

                        @include touchdevice {
                            background-color: transparent;
                        }

                        &:before {
                            @include desktop {
                                color: $green4;
                            }

                            @include touchdevice {
                                color: var(--primary-base-color);
                            }
                        }
                    }
                }

                .Facebook {
                    line-height: 55px;

                    @include facebook-icon;
                }

                .Instagram {
                    line-height: 55px;

                    @include instagram-icon;
                }

                .Youtube {
                    line-height: 50px;

                    @include youtube-icon;
                    display: none;

                    &:before {
                        margin-left: 1px;
                    }
                }

                .Tiktok {
                    line-height: 52px;

                    @include tiktok-icon;
                }
            }
        }
    }

    &_isShipingStep,
    &_isBillingStep,
    &_isSuccessStep,
    &_isCallbackStep {
        .Footer-Content {
            display: none;
        }
    }
}
