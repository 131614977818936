/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --product-card-background-color: #{$white};
    --product-card-padding: 10px 10px 20px;

    @include mobile {
        --product-card-padding: 10px 10px 15px;
    }

    --product-card-picture-mask-color: #f5f5f5;
    --product-card-color-border-color: #efefef;
    --product-card-text-border-color: #979797;
    --product-card-picture-label-background: rgba(255, 255, 255, .7);
    --product-card-reviews-background: rgba(255, 255, 255, .75);
    --product-card-wishlist-button-background: #{$white};
    --product-card-wishlist-button-color: #d8d5d5;
    --product-card-compare-button-background: var(--product-card-wishlist-button-background);
    --product-card-compare-button-color: var(--product-card-wishlist-button-color);
    --product-card-name-line-height: 1.2em;
    --product-card-name-max-rows: 2;
    --product-card-brand-line-height: 1.2em;
    --product-card-color-size: 15px;

    --product-card-wishlist-button-width: 29px;
    --product-card-wishlist-background: #{$white};
    --product-card-wishlist-heart-size: 17px;
    --product-card-compare-button-width: 29px;
    --product-card-compare-size: 14px;
}

.ProductCard {
    padding-left: 0;
    min-width: 0;
    display: flex;
    align-items: stretch;
    margin-bottom: 0;
    transition: $transition1;

    @include mobile {
        position: relative;
    }

    &:hover,
    &:focus {
        .ProductCard-ProductActions {
            .ProductWishlistButton-Button,
            .ProductCompareButton-Button {
                @include desktop-xl {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }

    &::before {
        content: none;
    }

    @include mobile {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 10px;
        }
    }

    &-Content {
        padding: 10px 0 0;
        display: flex;
        flex-grow: 1;
        flex-direction: column;

        .ProductCard-Link,
        .ProductCard-OutOfStockProduct {
            padding: 0;
            
            &:hover,
            &:focus {
                box-shadow: none;
            }
        }

        .Button {
            --button-line-height: 3.9rem;
            --button-text-transform: none;
            --button-color: var(--button-primary-revert-color);
            --button-background: var(--button-primary-revert-background);
            --button-hover-border: var(--button-primary-revert-hover-border);
            --button-hover-background: var(--button-primary-revert-hover-background);

            margin: 15px auto 0;

            &.ProductCard-SoldOutButton {
                --button-color: var(--button-secondary-revert-border);
                --button-border: var(--button-secondary-revert-border);
                --button-hover-border:  var(--button-secondary-revert-border);
                --button-hover-background: var(--button-secondary-revert-background);
                --button-hover-color: var(--button-secondary-revert-border);

                cursor: auto;
            }
        }
    }

    &-Name,
    &-Brand {
        width: 100%;
        max-width: 100%;
    }

    &-Name {
        color: var(--primary-dark-color);
        margin: 0 0 15px;
        line-height: var(--product-card-name-line-height);
        text-align: center;
        overflow: hidden;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: $letter-spacing-m;
        text-transform: capitalize;
        height: 40px;

        &:hover {
            @include touchdevice {
                text-decoration: none;
            }
        }
    }

    &-Brand {
        font-weight: 300;
        opacity: .5;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 1.1rem;

        @include after-mobile {
            font-size: 1.2rem;
        }
    }

    &-BrandAttributeValue {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    &-Picture {
        padding-bottom: 100%;

        .Image-Image {
            &::after {
                @include mobile {
                    background: none;
                }
            }
        }
    }

    &-PictureMissing {
        position: absolute;
        text-align: center;
        width: 100%;
        height: 20px;
        bottom: 0;
        margin: auto;
        top: 0;
    }

    &-FigureReview {
        display: flex;
        justify-content: center;

        @include mobile {
            position: relative;
        }

        @include desktop-xl {
            max-height: 184px;
        }
    }

    &-Reviews {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 106px;
        height: 26px;
        position: absolute;
        bottom: 0;
        background-color: var(--product-card-reviews-background);
        border-radius: 4px;
        letter-spacing: normal;
    }

    &-ConfigurableOptions {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        display: none;
    }

    &-Color {
        display: inline-block;
        width: var(--product-card-color-size);
        height: var(--product-card-color-size);
        border-radius: 50%;
        overflow: hidden;
        margin: 5px 7px 0 0;
        border: 1px solid var(--product-card-color-border-color);
    }

    &-String {
        border: 1px solid var(--product-card-text-border-color);
        color: var(--product-card-text-border-color);
        display: inline-block;
        text-align: center;
        padding: 0 6px;
        margin: 5px 7px 0 0;
        line-height: 15px;
        min-width: 20px;
    }

    &-PriceWrapper {
        text-align: center;
    }

    &-Price {
        text-align: center;
        display: inline-block;
    }

    &-PriceBadge {
        color: var(--primary-dark-color);
        font-size: 14px;
        margin-bottom: 0;
        display: inline-block;
        margin-right: 5px;
        text-transform: lowercase;
    }

    &-Figure {
        flex-grow: 1;
    }

    &-Link {
        .ProductCard-Name {
            &:hover {
                @include desktop-xl {
                    text-decoration: underline;
                } 
    
                @include touchdevice {
                    text-decoration: none;
                }
            }
        }
    }

    &-Link,
    &-OutOfStockProduct {
        color: inherit;
        display: flex;
        flex-direction: column;
        width: 100%;
        background: var(--product-card-background-color);
        padding: var(--product-card-padding);
        border-radius: 5px;

        &:hover,
        &:focus {
            @include desktop-xl {
                box-shadow: $box-shadow2;
                text-decoration: none;
            }

            @include touchdevice {
                box-shadow: none;
            }
        }
    }

    &-ProductActions {
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
        flex-direction: column;
        @include animation;
    }

    &-WishListButton {
        background-color: var(--product-card-wishlist-button-background);
        border: none;
    }

    .TextPlaceholder,
    .Image_isPlaceholder {
        opacity: 0;
        margin-bottom: 0;
        animation: appear 100ms 300ms forwards;
    }

    .ProductWishlistButton {
        margin: 0 0 10px 0;

        &-Button {
            --button-hover-background: var(--product-card-wishlist-background);
            --button-hover-border: var(--product-card-wishlist-background);
            --button-primary-revert-hover-border: var(--product-card-wishlist-background);
            --button-primary-revert-hover-background: var(--product-card-wishlist-background);

            width: var(--product-card-wishlist-button-width);
            height: var(--product-card-wishlist-button-width);
            box-shadow: $box-shadow1;
            border: none;

            @include desktop-xl {
                opacity: 0;
                visibility: hidden;
            }

            &_isInWishlist {
                --button-background: var(--product-card-wishlist-background);
                --button-hover-background: var(--product-card-wishlist-background);
                --button-hover-border: var(--product-card-wishlist-background);
                --button-primary-revert-hover-border: var(--product-card-wishlist-background);
                --button-primary-revert-hover-background: var(--product-card-wishlist-background);

                .ProductWishlistButton-Heart {
                    @include product-wishlist-icon-active;

                    &:before {
                        font-size: var(--product-card-wishlist-heart-size);
                        line-height: var(--product-card-wishlist-heart-size);
                    }
                }

                @include desktop-xl {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        &-Heart {
            top: 2px;
            left: 0;
            @include product-wishlist-icon;

            &:before {
                font-size: var(--product-card-wishlist-heart-size);
                line-height: var(--product-card-wishlist-heart-size);
            }
        }
    }

    .ProductCompareButton {
        margin: 0 0 10px 0;

        &-Button {
            --button-radius: 50%;
            --button-background: var(--product-card-wishlist-button-background);
            --button-hover-background: var(--product-card-wishlist-button-background);

            width: var(--product-card-compare-button-width);
            height: var(--product-card-compare-button-width);
            border: none;
            box-shadow: $box-shadow1;

            @include desktop-xl {
                opacity: 0;
                visibility: hidden;
            }
        }

        &-Icon {
            @include product-compare-icon;

            &::before {
                font-size: var(--product-card-compare-size);
                line-height: var(--product-card-compare-size);
            }
        }

        &_isActive {
            .ProductCompareButton-Icon {
                &::before {
                    color: var(--primary-dark-color);
                }
            }
        }
    }

    &_siblingsHaveBrands {
        .ProductCard-Brand {
            min-height: var(--product-card-brand-line-height);
        }
    }

    &_siblingsHaveConfigurableOptions {
        .ProductCard-ConfigurableOptions {
            min-height: 20px;
        }
    }
}
