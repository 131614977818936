/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.Field {
    margin-top: 20px;

    @include mobile {
        position: relative;
    }

    label {
        font-size: 1.4rem;
        line-height: 1.9rem;
        text-transform: capitalize;

        @include desktop {
            line-height: 2rem;
        }
    }

    &_type_checkbox {
        label {
            text-transform: none;
        }
    }

    &-Label {
        font-weight: $font-weight-bold;
        padding-bottom: 4px;
        letter-spacing: $letter-spacing-m;

        &_isDisabled {
            color: var(--form-label-disable-color);

            &::after {
                opacity: .5;
            }

            ~ .FieldSelect {
                &::before {
                    display: none;
                }
            }
        }

        &_isRequired {
            &::after {
                content: '*';
                color: var(--primary-error-color);
            }
        }
    }

    &-Message {
        margin-top: 5.5px;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: #{$letter-spacing-m};
        font-weight: #{$font-weight-regular};
        color: var(--primary-error-color);
    }

    &-Note {
        margin-bottom: .24rem;
        font-size: 1.2rem;
        font-weight: 600;
        color: var(--field-note);
        opacity: 0;
        height: 0;
        transition: height .1s ease-in, opacity .1s linear;
        will-change: height, opacity;

        @include mobile {
            margin-bottom: .28rem;
            font-size: 1.4rem;
        }

        &::before {
            content: '* ';
        }
    }

    &_isValid {
        input,
        textarea {
            border: 1px solid var(--input-border-color);
        }
    }

    &_hasError {
        input,
        textarea,
        select {
            border: 1px solid var(--primary-error-color);
        }

        .FieldSelect-Options_isExpanded {
            @include desktop {
                border-color: var(--primary-error-color);
            }
        }
    }

    &::placeholder {
        color: var(--field-note);
        font-size: 1.08rem;

        @include mobile {
            font-size: 1.26rem;
        }
    }

    input:focus + .Field-Note {
        opacity: 1;
        height: 100%;
    }

    &_style {
        &_inline {
            $size-mobile: 20px;
            $size: 30px;
            $size-input-mobile: 27px;
            $size-input: 37px;
            $input-width-mobile: 50px;
            $input-width: 50px;

            &.Field {
                display: flex;
                margin: 0;

                button {
                    position: relative;
                    width: $size-mobile;
                    height: $size-mobile;
                    border: solid 1px var(--primary-base-color);
                    background: var(--primary-base-color);
                    border-radius: 50%;

                    @include after-mobile {
                        width: $size;
                        height: $size;
                    }

                    &:last-of-type {
                        order: -1;
                    }

                    &:hover {
                        @include desktop {
                            background-color: var(--secondary-base-color);
                        }

                        @include touchdevice {
                            background: var(--primary-base-color);
                        }
                    }

                    &:disabled {
                        background-color: var(--qty-disabled-background-color);
                        border-color: var(--qty-disabled-background-color);
                        opacity: 1;
                    }
                }
            }

            input {
                height: $size-input-mobile;
                min-height: $size-input-mobile;
                width: $input-width-mobile;
                min-width: $input-width-mobile;
                border: none;
                padding: 0;
                text-align: center;
                font-weight: $font-weight-regular;
                border-bottom: 1px solid var(--primary-divider-color);
                border-radius: 0;

                @include after-mobile {
                    height: $size-input;
                    min-height: $size-input;
                    width: $input-width;
                    min-width: $input-width;
                    margin: 0 8px !important;
                }

                &:focus {
                    border: 0;
                    border-bottom: 1px solid var(--primary-divider-color);
                }
            }
        }
    }

    &_type {
        &_checkbox,
        &_radio {
            label {
                &:first-of-type {
                    display: inline-block;
                    max-width: calc(100vw - 2.4rem - 45px);
                    vertical-align: middle;

                    @include mobile {
                        max-width: calc(100vw - 2.8rem - 45px);
                    }

                    ~ label {
                        margin-left: 1.2rem;

                        @include mobile {
                            margin-left: 1.4rem;
                        }
                    }
                }

                &:last-of-type {
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }
    }

    [type='checkbox'],
    [type='radio'] {
        position: absolute;
        opacity: 0;
        padding: 0;
        min-width: 0;
        min-height: 0;

        + .input-control {
            --box-color: #{white};

            display: inline-block;
            position: relative;
            cursor: pointer;
            padding: 0;
            width: 1.8rem;
            height: 1.8rem;
            background-color: var(--box-color);
            border: 1px solid var(--input-border-color);
            transition-property: background-color, border-color;
            transition-duration: .25s;
            transition-timing-function: ease-in;
            will-change: background-color, border-color;
            margin-right: 1.2rem;

            &::after {
                --checkmark-color: transparent;

                content: '';
                position: absolute;
                pointer-events: none;
                background: var(--checkmark-color);
                transition-duration: .25s;
                transition-timing-function: ease-in;
                transition-property: background-color;
                will-change: background-color;
            }

            &:hover {
                @include after-mobile {
                    --box-color: var(--input-background-color);
                    border-color: var(--primary-dark-color);
                }

                &::after {
                    @include after-mobile {
                        --checkmark-color: var(--primary-dark-color);
                    }
                }
            }
        }

        &:disabled + .input-control {
            cursor: auto;
        }

        &:checked + .input-control {
            background-color: var(--input-radio-checked-background-color);
            border-color: var(--input-radio-checked-border-color);

            &::after {
                --checkmark-color: var(--input-radio-checked-checkmark-color);
            }
        }
    }

    [type='checkbox'] {
        + .input-control {
            border-radius: var(--input-checkbox-border-radius);
            float: left;

            &::after {
                left: .3rem;
                top: .72rem;
                width: .24rem;
                height: .24rem;
                box-shadow:
                    2px 0 0 var(--checkmark-color),
                    4px 0 0 var(--checkmark-color),
                    4px -2px 0 var(--checkmark-color),
                    4px -4px 0 var(--checkmark-color),
                    4px -6px 0 var(--checkmark-color),
                    4px -8px 0 var(--checkmark-color);
                transform: rotate(45deg);
                transition-property: background-color, box-shadow;
                will-change: background-color, box-shadow;
            }
        }

        &:checked {
            + .input-control {
                background-color: var(--input-checkbox-checked-background-color);
                border-color: var(--input-checkbox-checked-border-color);
            }
        }
    }

    [type='radio'] {
        + .input-control {
            border-radius: 50%;
            float: left;

            &::after {
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                width: .6rem;
                height: .6rem;
                border-radius: 50%;
                transition-property: background-color, box-shadow;
                will-change: background-color, box-shadow;

                @include mobile {
                    width: .7rem;
                    height: .7rem;
                }
            }
        }

        &:focus + .input-control {
            --box-color: var(--secondary-light-color);

            border-color: var(--primary-base-color);

            &::after {
                --checkmark-color: #{$white};
            }
        }
    }

    &-Labels {
        span {
            letter-spacing: 1.05px;
        }
    }

    &_isCompanionField{
        display: none;
    }

    .FieldInput {
        &-Password {
            @include mobile {
                position: relative;
            }

            input {
                --input-padding: 1.2rem 1.5rem;

                padding-right: 41px;
            }

            &_togglePasswordActive {
                .FieldInput-TogglePassword {
                    padding: 4px 12px 0;
                    line-height: 3.6rem;
        
                    --button-padding: 2px 12px 0;

                    @include hide-password-icon;

                    &::before {
                        color: var(--primary-dark-color);
                    }
                }
            }
        }

        &-TogglePassword {
            --button-padding: 0 13px;
            --button-background: transparent;
            --button-line-height: normal;
            --button-border-width: 0;
            --button-hover-background: transparent;

            width: auto;
            position: absolute;
            right: 0;
            top: 0;
            margin-top: 0;
            padding: 0 13px;
            line-height: 4rem;

            @include show-password-icon;

            span {
                display: none;
            }
        }
    }
}
