/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --product-compare-button-width: 35px;
    --product-compare-size: 15px;
    --product-compare-border: #{$grey7};
    --product-compare-border-hover: var(--primary-dark-color);
    --product-compare-background-active: #{$grey9};
}

.ProductCompareButton {
    --loader-scale: .6;
    --button-padding: 0;

    --button-color: var(--product-compare-border);
    --button-background: transparent;
    --button-border: var(--product-compare-border);

    --button-hover-color: var(--product-compare-border-hover);
    --button-hover-background: transparent;
    --button-hover-border: var(--product-compare-border-hover);

    vertical-align: middle;
    display: inline-block;

    &-Button {
        --button-color: var(--product-compare-border);
        --button-line-height: normal;
        --button-radius: 50%;

        width: var(--product-compare-button-width);
        height: var(--product-compare-button-width);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        &:focus {
            color: var(--product-compare-border) !important;
            border-color: var(--product-compare-border) !important;
        }

        > span {
            display: none;
        }
    }

    &-Icon {        
        @include product-compare-icon;
        margin-top: 2px;
    }

    &:hover {
        .ProductCompareButton-Icon {
            &::before {
                @include after-mobile {
                    color: var(--primary-dark-color);
                }
            }
        }
    }

    &_isActive {
        .ProductCompareButton-Button {
            --button-color: var(--product-compare-border-hover);
        }

        .ProductCompareButton-Icon {
            &::before {
                color: var(--primary-dark-color);
            }
        }
    }
}
