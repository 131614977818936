/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
.ProductCompareAddSuccessPopup {
    &-wrapper {
        .ProductCompareAddSuccessPopup-product {
            &Image {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                overflow: hidden;
                height: 0;
                width: 100%;
                padding-bottom: var(--product-compare-success-popup-image);
                position: relative;

                img {
                    position: absolute;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    object-position: 50% 50%;
                    object-fit: contain;
                }
            }
        }
    }
}