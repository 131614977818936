/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
:root {
    --search-bar-max-width: 450px;
    --search-bar-width: 270px;
    --search-clear-background: var(--secondary-base-color);
    --search-field-background: var(--secondary-base-color);
    --search-field-border-color: #D9D9D9;

    --search-field-visible-background: #{$white};
    --search-input-desktop-border-color: #000;

    --search-area-background: var(--header-background-color);
    --search-placeholder-color: #{$grey5};
    --search-field-color: var(--primary-dark-color);
    --search-clear-background: var(--primary-base-color);
    --search-icon-color: #{$white};
}

.SearchField {
    @include before-desktop-l {
        flex-grow: 1;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        background: var(--search-area-background);
        width: 100%;
        top: 0;
        padding: 1.5rem 2rem;
        left: 0;
    }

    @include desktop {
        order: 1;
        max-width: 40rem;
        width: 100%;
    }

    @include desktop-xl {
        max-width: 50rem;
        grid-area: search;
    }

    &_isVisible {
        opacity: 1;
        pointer-events: all;

        .SearchOverlay-Results {
            visibility: visible;

            @include desktop {
                &:before {
                    content: '';
                    height: .1rem;
                    width: calc(100% - 2.7rem);
                    margin: 0 1.5rem;
                    background: $grey7;
                    position: absolute;
                    top: 0rem;
                    z-index: 3;
                  }
            }
        }

        .SearchField-CloseIcon {
            @include desktop {
                border-radius: 0 5px 0 0;
            }
        }
    }

    &_isActive {
        // --search-bar-width: 27vw;
        // --search-field-border-color: var(--primary-light-color);
    }

    &-Wrapper {
        @include desktop {
            margin-right: 1.2rem;
        }

        @include desktop-l {
            margin-right: 0;
        }
    }

    &-SearchInnerWrapper {
        display: flex;
        position: relative;
    }

    &-Input {
        transition-property: opacity, background-color;
        transition-duration: 200ms;
        margin: 0;
        border-radius: 5px 0 0 5px;
        background: $white;
        height: 4rem;
        border: 1px solid var(--search-field-border-color);
        border-right: 0 !important;
        width: calc(100% - 4rem);
        padding: 1rem 1.5rem;
        font-size: 1.4rem;
        letter-spacing: $letter-spacing-m;
        color: var(--search-field-color);
        line-height: 2rem;

        @include before-desktop {
            max-width: 100%;
        }

        &:focus,
        &:active {
            --input-color: var(--search-field-color);
            border-color: var(--search-field-border-color);
        }
    }

    &-Placeholder {
        font-size: 1.4rem;
        height: min-content;
        left: 1.5rem;
        pointer-events: none;
        position: absolute;
        top: 1.4rem;
        visibility: hidden;
        letter-spacing: $letter-spacing-m;
        color: var(--search-placeholder-color);
        font-style: italic;

        &_isPlaceholderVisible {
            visibility: visible;
        }
    }

    &-SearchIcon,
    &-CloseIcon {
        cursor: pointer;

        @include desktop-l {
            height: 24px;
            width: 16px;
        }
    }

    &-SearchIcon {
        @include search-icon;
        border-radius: 0 5px 5px 0;
        background: #{$green7};
        height: 4rem;
        width: 4rem;
        padding: 1.1rem;
        position: relative;

        @include desktop {
            &:after {
                content: '';
                height: .1rem;
                width: 84%;
                margin: 0 1.5rem;
                background: #{$green7};
                position: absolute;
                bottom: 0;
                left: -15px;
                z-index: 3;
              }
        }

        &:before {
            left: 5px;
            top: 6px;
            position: absolute;
        }

        &:hover {
            background: #{$green4};

            @include touchdevice {
                background: var(--primary-base-color);
            }
        }
    }

    &-CloseIcon {
        @include before-desktop-l {
            @include icon($icon-angel-prev, before, 1.3rem, var(--search-icon-color));
            padding: 1rem 2rem 1rem 0;
        }

        @include desktop-l {
            @include close-button;
            border-radius: 0 5px 5px 0;
            background: $white;
            height: 4rem;
            width: 4rem;
            padding: 1.1rem;
            border-top: 1px solid var(--search-field-border-color);
            border-right: 1px solid var(--search-field-border-color);
            border-bottom: 1px solid var(--search-field-border-color);

            &::before,
            &::after {
                height: 22px;
                left: 18px;
                top: 9px;
                width: 2px;
                background: var(--search-clear-background);
            }
        }
    }
}
