/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

 h1 {
    color: $h1-font-color;
    font-size: $h1-font-size-desktop;
    line-height: $h1-font-line-height-desktop;
    font-weight: $h1-font-weight;
    letter-spacing: $h1-letter-spacing;

    @include mobile {
        font-size: $h1-font-size-mobile;
        line-height: $h1-font-line-height-mobile;
    }
}

h2 {
    color: $h2-font-color;
    font-size: $h2-font-size-desktop;
    line-height: $h2-font-line-height-desktop;
    font-weight: $h2-font-weight;
    letter-spacing: $h2-letter-spacing;

    @include mobile {
        font-size: $h2-font-size-mobile;
        line-height: $h2-font-line-height-mobile;
    }
}

h3 {
    color: $h3-font-color;
    font-size: $h3-font-size-desktop;
    line-height: $h3-font-line-height-desktop;
    font-weight: $h3-font-weight;
    letter-spacing: $h3-letter-spacing;

    @include mobile {
        font-size: $h3-font-size-mobile;
        line-height: $h3-font-line-height-mobile;
    }
}

h4 {
    color: $h4-font-color;
    font-size: $h4-font-size-desktop;
    line-height: $h4-font-line-height-desktop;
    font-weight: $h4-font-weight;
    letter-spacing: $h4-letter-spacing;

    @include mobile {
        font-size: $h4-font-size-mobile;
        line-height: $h4-font-line-height-mobile;
    }
}

h5 {
    color: $h5-font-color;
    font-size: $h5-font-size-desktop;
    line-height: $h5-font-line-height-desktop;
    font-weight: $h5-font-weight;
    letter-spacing: $h5-letter-spacing;

    @include mobile {
        font-size: $h5-font-size-mobile;
        line-height: $h5-font-line-height-mobile;
    }
}

h6 {
    color: $h6-font-color;
    font-size: $h6-font-size-desktop;
    line-height: $h6-font-line-height-desktop;
    font-weight: $h6-font-weight;
    letter-spacing: $h6-letter-spacing;

    @include mobile {
        font-size: $h6-font-size-mobile;
        line-height: $h6-font-line-height-mobile;
    }
}

.SubTitle-Primary {
    color: $subtitle-primary-font-color;
    font-size: $subtitle-primary-font-size;
    line-height: $subtitle-primary-font-line-height;
    font-weight: $subtitle-primary-font-weight;
    letter-spacing: $subtitle-primary-letter-spacing;
    margin: $subtitle-primary-margin;
}

.SubTitle-Secondary {
    color: $subtitle-secondary-font-color;
    font-size: $subtitle-secondary-font-size;
    line-height: $subtitle-secondary-font-line-height;
    font-weight: $subtitle-secondary-font-weight;
    letter-spacing: $subtitle-secondary-letter-spacing;
    margin: $subtitle-primary-margin;
}

p {
    color: $paragraph-font-color;
    font-size: $paragraph-font-size-desktop;
    line-height: $paragraph-font-line-height-desktop;
    font-weight: $paragraph-font-weight;
    letter-spacing: $paragraph-letter-spacing;

    @include mobile {
        font-size: $paragraph-font-size-mobile;
        line-height: $paragraph-font-line-height-mobile;
    }
}
