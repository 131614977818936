/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @mixin close-button {
    &::before,
    &::after {
        content: '';
        position: absolute;
        width: 1px;
        height: 20px;
        transform-origin: 50% 50%;
        top: 7px;
        transform: rotate(-45deg);
        background-color: var(--header-color);
    }

    &::after {
        transform: rotate(45deg);
    }
}

@mixin close-button-popup {
    &::before,
    &::after {
        content: '';
        position: absolute;
        width: 2px;
        height: 19px;
        transform-origin: 50% 50%;
        top: 7px;
        margin-left: -1px;
        transform: rotate(-45deg);
        background-color: $white;
    }

    &::after {
        transform: rotate(45deg);
    }
}

@mixin close-button-wishlist {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e932";
        font-size: 1.8rem;
        line-height: 1.8rem;
        color: var(--primary-divider-color);
        font-weight: normal;
    }
}

@mixin cart-close-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e616";
        font-size: 3.4rem;
        line-height: 3.4rem;
        color: var(--primary-dark-color);
        font-weight: normal;
    }
}

@mixin coupon-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e93f";
        font-size: 1.87rem;
        line-height: 1.87rem;
        color: $grey4;
        font-weight: normal;
    }
}

@mixin down-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e935";
        font-size: .7rem;
        line-height: .7rem;
        color: var(--input-border-color);
        font-weight: normal;
    }
}

@mixin up-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e922";
        font-size: .7rem;
        line-height: .7rem;
        color: var(--input-border-color);
        font-weight: normal;
    }
}

@mixin right-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e93e";
        font-size: 1.2rem;
        line-height: 1.2rem;
        color: var(--primary-divider-color);
        font-weight: normal;
    }
}

@mixin menu-right-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e925";
        font-size: 1.1rem;
        line-height: 1.1rem;
        color: $grey4;
        font-weight: normal;
    }
}

@mixin shipping-step-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e941";
        font-size: 1.6rem;
        line-height: 1.6rem;
        color: $green4;
        font-weight: normal;
    }
}

@mixin order-tracker-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e947";
        color: $white;
        font-size: 2.1rem;
        line-height: 2.1rem;
        font-weight: normal;
    }
}

@mixin billing-step-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e940";
        font-size: 1.2rem;
        line-height: 1.2rem;
        color: $green4;
        font-weight: normal;
    }
}

@mixin share-button-wishlist {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e943";
        font-size: 1.8rem;
        line-height: 1.8rem;
        color: var(--primary-dark-color);
        font-weight: normal;
    }
}

@mixin delete-button {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e93c";
        font-size: 1.8rem;
        line-height: 1.8rem;
        color: $grey7;
        font-weight: normal;
    }

    &:hover {
        &::before {
            @include desktop {
                color: var(--primary-dark-color);
            }

            @include touchdevice {
                color: $grey7;
            }
        }
    }
}

@mixin secure-checkout-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e90b";
        font-size: 1.4rem;
        line-height: 1.4rem;
        color: $white;
        font-weight: normal;
    }
}

@mixin slider-left-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e93a";
        font-size: 1.8rem;
        line-height: 1.8rem;
        color: var(--primary-dark-color);
        font-weight: normal;
    }
}

@mixin slider-right-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e93b";
        font-size: 1.8rem;
        line-height: 1.8rem;
        color: var(--primary-dark-color);
        font-weight: normal;
    }
}

@mixin instagram-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e929";
        font-size: 21.5px;
        line-height: 21.5px;
        color: $white;
        font-weight: normal;
    }
}

@mixin facebook-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e904";
        font-size: 20.7px;
        line-height: 20.7px;
        color: $white;
        font-weight: normal;
    }
}

@mixin youtube-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e934";
        font-size: 16.63px;
        line-height: 16.63px;
        color: $white;
        font-weight: normal;
    }
}

@mixin tiktok-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e94a";
        font-size: 20px;
        line-height: 20px;
        color: $white;
        font-weight: normal;
    }
}

@mixin whatsapp-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e92c";
        font-size: 16px;
        line-height: 29px;
        color: var(--primary-divider-color);
        font-weight: normal;
    }
}

@mixin twitter-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e90a";
        font-size: 14px;
        line-height: 28px;
        color: var(--primary-divider-color);
        font-weight: normal;
    }
}

@mixin show-password-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e942";
        font-size: 12px;
        line-height: 12px;
        color: var(--table-border-color);
        font-weight: normal;
    }
}

@mixin hide-password-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e946";
        font-size: 16px;
        line-height: 16px;
        color: var(--table-border-color);
        font-weight: normal;
    }
}

@mixin customer-email-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e933";
        font-size: 13.8px;
        line-height: 13.8px;
        color: var(--primary-dark-color);
        font-weight: normal;
    }
}

@mixin customer-number-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e914";
        font-size: 16.69px;
        line-height: 16.69px;
        color: var(--primary-dark-color);
        font-weight: normal;
    }
}

@mixin store-address-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e945";
        font-size: 1.5rem;
        line-height: 1.5rem;
        color: var(--primary-base-color);
        font-weight: normal;
    }
}

@mixin store-email-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e928";
        font-size: 1.2rem;
        line-height: 1.2rem;
        color: var(--primary-base-color);
        font-weight: normal;
    }
}

@mixin store-number-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e944";
        font-size: 1.5rem;
        line-height: 1.5rem;
        color: var(--primary-base-color);
        font-weight: normal;
    }
}

@mixin clear-button {
    &::before,
    &::after {
        content: '';
        position: absolute;
    }

    &::before {
        top: 12px;
        left: 12px;
        width: 10px;
        height: 14px;
        transform: perspective(10px) rotateX(-10deg);
        border: {
            left: 1px solid var(--primary-dark-color);
            right: 1px solid var(--primary-dark-color);
            bottom: 1px solid var(--primary-dark-color);
            bottom-left-radius: 3px;
            bottom-right-radius: 3px;
        }
    }

    &::after {
        left: 14px;
        top: 7px;
        width: 6px;
        height: 2px;
        box-shadow:
            6px 2px 0 -1px var(--primary-dark-color),
            -6px 2px 0 -1px var(--primary-dark-color),
            -2px 2px 0 -1px var(--primary-dark-color),
            2px 2px 0 -1px var(--primary-dark-color);
        border: {
            left: 1px solid var(--primary-dark-color);
            right: 1px solid var(--primary-dark-color);
            top: 1px solid var(--primary-dark-color);
            top-left-radius: 2px;
            top-right-radius: 2px;
        };
    }
}

@mixin back-button {
    &::before,
    &::after {
        content: '';
        position: absolute;
    }

    &::before {
        width: 18px;
        height: 1px;
        top: 17px;
        right: 8px;
        background-color: var(--header-color);
    }

    &::after {
        width: 8px;
        height: 8px;
        top: 13px;
        left: 10px;
        transform: rotate(-45deg);

        border: {
            top: 1px solid var(--header-color);
            left: 1px solid var(--header-color);
        }
    }
}

@mixin clear-search-button {
    @include close-button;

    border-radius: 50%;
    background-color: var(--search-clear-background);

    height: 15px;
    width: 15px;
    position: absolute;
    right: 2.4rem;

    &::before,
    &::after {
        height: 9px;
        width: 1px;
        top: 3px;
        left: 7px;
        background-color: var(--header-background);
    }
}

@mixin edit-button {
    // border-radius: 10px; <- Wait chrome until bug will be fixed
    box-shadow:
        inset 0 0 0 8px var(--header-background),
        inset 0 0 0 9px var(--header-color);

    &::before,
    &::after {
        content: '';
        position: absolute;
        transform-origin: 0 0;
        transform: rotate(45deg);
        top: 4px;
        right: 2px;
    }

    &::before {
        background-color: var(--header-background);
        box-shadow: 0 0 0 2px var(--header-background);
        width: 4px;
        height: 16px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border: {
            top: 1px solid var(--header-color);
            left: 1px solid var(--header-color);
            right: 1px solid var(--header-color);
        }
    }

    &::after {
        background-color: var(--header-color);
        width: 6px;
        top: 4px;
        height: 23px;
        clip-path:
            polygon(
                3px 23px,
                6.5px 17px,
                3px 17px,
                3px 4.5px,
                0 4.5px,
                0 3.5px,
                5px 3.5px,
                5px 4.5px,
                3px 4.5px,
                3px 17px,
                -.5px 17px,
                3px 23px,
                3px 21.5px,
                1px 18px,
                5px 18px,
                3px 21.5px,
            );
    }
}

@mixin lock-button {
    display: inline-block;
    margin-right: 9px;

    &::before,
    &::after {
        content: '';
    }

    &::after {
        display: block;
        position: relative;
        top: 1px;
        width: 10px;
        height: 9px;
        background: currentColor;
        border-radius: 1px;
        box-shadow: inset 0 0 0 -2px var(--button-background);
    }

    &::before {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: -4px;
        border-radius: 50%;
        border: 1px solid currentColor;
        width: 6px;
        height: 6px;
    }
}

@mixin home-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e936";
        color: $white;
        font-size: 2rem;
        line-height: 2rem;
        font-weight: normal;
    }
}

@mixin menu-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e937";
        color: $white;
        font-size: 1.7rem;
        line-height: 2rem;
        font-weight: normal;
    }
}

@mixin search-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e615";
        color: $white;
        font-size: 28px;
    }
}

@mixin back-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e93d";
        color: var(--header-title-color);
        font-size: 16px;
    }
}

@mixin location-picker-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e906";
        color: #{$green4};
        font-size: 2rem;

        @include mobile {
            color: var(--header-icon-color);
            font-size: 1.2rem;
        }
    }
}

@mixin virtual-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e938";
        color: var(--header-icon-text-color);
        font-size: 2.25rem;
        font-weight: normal;
    }
}

@mixin my-account-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e912";
        color: var(--header-icon-text-color);
        font-size: 2rem;
        font-weight: normal;

        @include before-desktop-l {
            font-size: 2rem;
            line-height: 2rem;
            color: $white;
        }
    }
}

@mixin mini-cart-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e902";
        color: var(--header-icon-text-color);
        font-size: 2.5rem;
        line-height: 1.8rem;
        font-weight: normal;

        @include before-desktop-l {
            font-size: 2rem;
            line-height: 2rem;
            color: $white;
        }
    }
}

@mixin wishlist-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\f08a";
        color: var(--header-icon-text-color);
        font-size: 2rem;
        line-height: 2rem;
        font-weight: normal;

        @include before-desktop-l {
            font-size: 2rem;
            line-height: 2rem;
            color: var(--header-icon-color);
        }
    }
}

@mixin ios-share {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e943";
        font-size: 1.1rem;
        line-height: 1.1rem;
        color: $white;
        font-weight: normal;
    }
}

@mixin product-compare-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e92d";
        font-size: 1.5rem;
        line-height: 1.5rem;
        color: $grey7;
        font-weight: normal;
    }

    &:hover {
        &::before {
            @include desktop {
                color: var(--primary-dark-color);
            }

            @include touchdevice {
                color: $grey7;
            }
        }
    }
}

@mixin product-wishlist-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\f08a";
        font-size: 1.8rem;
        line-height: 1.8rem;
        color: $grey7;
        font-weight: normal;
    }

    &:hover {
        &::before {
            @include desktop {
                color: var(--primary-dark-color);
            }
        }
    }
}

@mixin product-wishlist-icon-active {
    &::before {
        font-family: $icomoon-font-family;
        content: "\f004";
        font-size: 1.8rem;
        line-height: 1.8rem;
        color: var(--primary-light-color);
        font-weight: normal;
    }
}

@mixin order-status-checked {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e919";
        font-size: 1.1rem;
        line-height: 26px;
        color: $white;
        font-weight: normal;
        text-align: center;
    }
}

@mixin calendar-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e948";
        font-size: 15px;
        line-height: 15px;
        color: $grey7;
        font-weight: normal;
    }
}

@mixin base-plus-minus-icon {
    $size: 20px;
    @include desktop {
        $size: 30px;
    }
    $line-size: 11px;

    width: $size;
    height: $size;

    &::before,
    &::after {
        content: '';
        display: block;
        height: 2px;
        background-color: $white;
        width: $line-size;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &::after {
        transform: translate(-50%, -50%) rotate(90deg);
    }
}

@mixin minus-icon {
    @include base-plus-minus-icon;

    &::after {
        display: none;
    }
}

@mixin plus-icon {
    @include base-plus-minus-icon;
}

@mixin ios-plus {
    $color-storm-grey: $white;

    background:
        linear-gradient(var(--primary-dark-color), var(--primary-dark-color)),
        linear-gradient(var(--primary-dark-color), var(--primary-dark-color)),
        $color-storm-grey;

    background-position: center;
    background-repeat: no-repeat;
    background-size: 53% 1px, 1px 50%;
    border-radius: 0;
    height: 13px;
    margin: 0 0.5rem;
    width: 13px;
}

@mixin share-button {
    --header-color: var(--primary-dark-color);

    $share-button-stroke-width: 1px;

    background-image: linear-gradient(to right, var(--header-color) 15%, var(--header-background) 0);
    background-repeat: repeat-x;
    background-size: 12px $share-button-stroke-width;
    border-radius: 1px;
    padding: calc(6px - #{$share-button-stroke-width}) calc(8px - #{$share-button-stroke-width}) !important;

    border: {
        bottom: $share-button-stroke-width solid var(--header-color);
        left: $share-button-stroke-width solid var(--header-color);
        right: $share-button-stroke-width solid var(--header-color);
    }

    @include desktop {
        background-image: linear-gradient(to right, var(--header-color) 20%, var(--my-account-content-background) 0);
        background-size: 15px $share-button-stroke-width;
        padding: calc(11px - #{$share-button-stroke-width});
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
    }

    &::before {
        background-color: var(--header-color);
        height: 10px;
        left: 6px;
        top: -4px;
        width: $share-button-stroke-width;

        @include desktop {
            height: 13px;
            left: 8px;
            top: -6px;
            width: 2px;
        }
    }

    &::after {
        height: 4px;
        left: 4px;
        top: -4px;
        transform: rotate(45deg);
        width: 4px;

        border: {
            left: $share-button-stroke-width solid var(--header-color);
            top: $share-button-stroke-width solid var(--header-color);
        }

        @include desktop {
            height: 6px;
            left: 5px;
            top: -7px;
            width: 6px;
        }
    }
}

@mixin email-icon {
    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        box-sizing: border-box;
        border: 1px solid $white;
    }

    &::before {
        width: 18px;
        height: 14px;
        transform: translate(-50%, -50%);
    }

    &::after {
        width: 12px;
        height: 12px;
        transform: rotate(45deg) translate(calc(-50% - 4px), calc(-50% - 4px));
        transform-origin: top left;
    }
}

@mixin compare-icon($color) {
    width: 1px;
    height: 25px;
    background-color: $color;

    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        width: 7px;
        height: calc(100% - 7px);
        box-sizing: border-box;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 1px;
        box-sizing: border-box;
    }

    &::before {
        left: -7px;
        background-color: $color;
    }

    &::after {
        left: 3px;
        border: 1px solid $color;
        border-left: none;
    }
}

@mixin prev-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e924";
        font-size: 11px;
        line-height: 15px;
        color: $grey20;
        font-weight: normal;
    }
}

@mixin next-icon {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e926";
        font-size: 11px;
        line-height: 15px;
        color: $grey20;
        font-weight: normal;
    }
}

@mixin filter-icon {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e907";
        font-size: 12px;
        line-height: 12px;
        color: $grey4;
        font-weight: normal;
    }
}

@mixin lightning-icon-left {
    &::before {
        font-family: $icomoon-font-family;
        content: "\e949";
        font-size: 27px;
        line-height: 27px;
        color: var(--primary-base-color);
        font-weight: normal;
        text-align: center;
        margin-right: 2rem;
    }
}

@mixin lightning-icon-right {
    &::after {
        font-family: $icomoon-font-family;
        content: "\e949";
        font-size: 27px;
        line-height: 27px;
        color: var(--primary-base-color);
        font-weight: normal;
        text-align: center;
        margin-left: 2rem;
    }
}

//  Custom Icons
$icon-lightning: "\e949";
$icon-calendar: "\e948";
$icon-order-tracker: "\e947";
$icon-toggle-off-normal: "\e946";
$icon-contact-phone: "\e944";
$icon-contact-location: "\e945";
$icon-eye-reveal: "\e942";
$icon-share-wishlist: "\e943";
$icon-billing: "\e940";
$icon-shipping: "\e941";
$icon-coupon: "\e93f";
$icon-filters: "\e907";
$icon-attachment: "\e911";
$icon-delete-filters: "\e913";
$icon-expand-filters: "\e916";
$icon-feather-left: "\e93d";
$icon-feather-right: "\e93e";
$icon-delete: "\e93c";
$icon-cart: "\e902";
$icon-location-picker: "\e906";
$icon-secure-checkout: "\e90b";
$icon-my-account: "\e912";
$icon-telephone: "\e914";
$icon-compare: "\e92d";
$icon-email: "\e933";
$icon-youtube: "\e934";
$icon-question-circle: "\f059";
$icon-heart: "\f004";
$icon-star: "\f005";
$icon-star-o: "\f006";
$icon-heart-o: "\f08a";
$icon-phone: "\f095";
$icon-filter1: "\f0b1";
$icon-hero-banner-left: "\e93a";
$icon-hero-banner-right: "\e93b";
$icon-star-rating: "\e939";
$icon-home: "\e936";
$icon-menu-m: "\e937";
$icon-virtual: "\e938";
$icon-angle-up: "\e922";
$icon-angle-down: "\e935";
$icon-close-popup: "\e932";
$icon-checkmark1: "\e931";
$icon-down-large: "\e92f";
$icon-up-large: "\e930";
$icon-checkout-payment: "\e92e";
$icon-arrow: "\e923";
$icon-arrow-left-thin: "\e924";
$icon-arrow-right: "\e925";
$icon-arrow-right-thin: "\e926";
$icon-checkout-shipping: "\e927";
$icon-feather-mail: "\e928";
$icon-insta: "\e929";
$icon-minus-large: "\e92a";
$icon-plus-large: "\e92b";
$icon-whatsapp: "\e92c";
$icon-send-email: "\e921";
$icon-minus-faq: "\e91f";
$icon-plus-faq: "\e920";
$icon-checkbox-checked: "\e91d";
$icon-checkbox: "\e91e";
$icon-radio-button-checked: "\e91b";
$icon-radio-button: "\e91c";
$icon-review-star: "\e91a";
$icon-filter-checked: "\e919";
$icon-share: "\e918";
$icon-size-guide: "\e917";
$icon-gift: "\e915";
$icon-information: "\e910";
$icon-close-filter: "\e90f";
$icon-angel-next: "\e90d";
$icon-angel-prev: "\e90e";
$icon-copyright: "\e90c";
$icon-rss: "\e909";
$icon-twitter: "\e90a";
$icon-down-arrow: "\e903";
$icon-facebook: "\e904";
$icon-google: "\e905";
$icon-minus-icon: "\e908";
$icon-search1: "\e901";
$icon-cart1: "\e900";
$icon-help: "\e623";
$icon-gift-registry: "\e628";
$icon-present: "\e629";
$icon-account: "\e627";
$icon-arrow-up-thin: "\e633";
$icon-arrow-down-thin: "\e626";
$icon-wishlist-full: "\e600";
$icon-wishlist-empty: "\e601";
$icon-next: "\e608";
$icon-menu: "\e609";
$icon-search: "\e615";
$icon-remove: "\e616";
$icon-prev: "\e617";
$icon-up: "\e621";
$icon-down: "\e622";
$icon-tiktok: "\e94a";