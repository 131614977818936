.kemanapwa-home-page-recently-viewed {
    .RecentlyViewedWidget {
        h3 {
            @include h4;

            width: 100%;
            text-align: center;
            font-size: 18px;
            line-height: 24px;

            @include mobile {
                margin-bottom: 30px;
            }

            @include after-mobile {
                font-size: 24px;
                line-height: 32px;
            }
        }
    }
}