/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
:root {
    --cartOverlay-empty-color: #{$default-primary-dark-color};
}

.CartOverlay {
    position: fixed;
    top:0;
    bottom: 0;
    width: 380px;
    right: -400px;
    padding: 15px 20px;
    box-shadow: $box-shadow2;
    height: auto;
    opacity: 1;
    transition: 400ms ease-in all;
    overflow: hidden;

    &_isVisible {
        right: 0;
    }

    &-Promo {
        background-color: $orange1;
        border-radius: 4px;
        padding: 1.3rem 2rem;
        color: var(--primary-dark-color);

        ~ .CartOverlay-Items {
            height: calc(100vh - 270px);
        }
    }
    
    &-OutOfStockRemovalError {
        background: var(--notification-background);
        border-radius: 5px;
        color: var(--primary-error-color);
        border: 1px solid var(--primary-error-color);
        padding: 1.3rem 3rem 1.3rem 2rem;
        line-height: 1.6rem;
        position: relative;

        ~ .CartOverlay-Items {
            height: calc(100vh - 270px);
        }

        + .CartOverlay-Promo {
            margin-top: 2rem;
        }

        .CartOverlay-Clear {
            position: absolute;
            right: 0;
            top: 0;
            @include cart-close-icon;

            span {
                display: none;
            }
        }
    }

    .CartPage-CartBrandRuleMessage {
        line-height: 18px;
        margin-bottom: 0;
        background: $green3;
        border-radius: 5px;
        color: $green2;
        border: 1px solid $green1;
        padding: 1.5rem 2rem;
        display: inline-block;
        width: 100%;
        margin-top: 1rem;

        @include mobile {
            margin-top: 2rem;
            margin-left: 1rem;
            margin-right: 1rem;
            width: calc(100% - 2rem);
        }

        &:first-of-type {
            @include desktop {
                margin-top: 0;
            }
        }

        + .CartPage-OutOfStockRemovalError {
            @include mobile {
                margin-top: 0;
            }
        }
    }

    &-Empty {
        text-align: center;

        img {
            width: 140px;
            margin: 30px auto;
        }

        p {
            color: var(--cartOverlay-empty-color);
        }
    }

    &-Header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 13px;

        h6 {
            font-size: 2rem;
            letter-spacing: $letter-spacing-m3;
        }
    }

    &-Close {
        @include cart-close-icon;
        
        &:after {
            margin-right: -.9rem;
        }

        span {
            display: none;
        }
    }

    &-Items {
        margin-top: 2rem;
        overflow-y: scroll;
        height: calc(100vh - 212px);
        margin-right: -21px;
        padding-right: 8px;

        @include desktop {
            @include custom-scroll;
        }
    }

    &-Total {
        background: $blue1;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        dt {
            font-size: 1.5rem;
            line-height: 2rem;
        }

        dd {
            font-size: 1.7rem;
            font-weight: $font-weight-black;
            letter-spacing: $letter-spacing-m4;
            color: $default-primary-dark-color;
        }
    }

    &-Additional {
        position: absolute;
        bottom: 0;
        width: 100%;
        margin: 0 -20px;
    }

    &-Shipping {
        display: none;
    }

    &-Actions {
        display: flex;
        justify-content: space-between;
        padding: 1.5rem 2rem 2rem;
    }

    &-CheckoutButton {
        margin-left: .7rem;
        width: calc(100% - 127px);
        @include secure-checkout-icon;

        &:before {
            margin-right: .7rem;
        }
    }

    &-CartButton {
        display: block;
        width: 127px;
    }

    &-CartButton,
    &-CheckoutButton {
        --button-padding: 0 10px;

        &:focus {
            text-decoration: none;
        }
    }

    &-Discount {
      display: none;
    }
}