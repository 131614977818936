/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.NoMatch {
    opacity: 0;
    animation: appear 100ms 300ms forwards;

    max-width: var(--content-wrapper-width);
    padding-left: 10px;
    padding-right: 10px;
    margin-left: auto;
    margin-right: auto;

    @include mobile {
        min-height: calc(100vh - var(--header-height) - var(--breadcrumbs-height));
    }

    .CmsBlock-Wrapper {
        text-align: center;
    }

    &-Figure {
        width: 100%;
        max-width: 284px;
        max-height: 222px;
        margin-bottom: 3rem;
        margin-left: auto;
        margin-right: auto;

        @include desktop {
            max-width: 290px;
        }

        .Image_ratio_square {
            padding-bottom: 222px;
        }
    }

    &-Title {
        margin-bottom: 2rem;
    }

    p {
        --body-paragraph-color: var(--content-paragraph-color);

        margin-bottom: 3rem;
    }

    .Button {
        margin-bottom: 10rem;

        @include mobile {
            width: 100%;
            margin-bottom: 4.5rem;
        }
    }

    &-Subtitle {
        font-size: 3.6rem;
        font-weight: 700;
        margin: 0;

        @include mobile {
            font-size: 4.2rem;
        }
    }

    &-Wrapper {
        text-align: center;
    }
}
