/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.NotificationList {
    position: fixed;
    z-index: 500;
    top: var(--header-height);
    right: 30px;
    max-width: 550px;
    list-style: none;

    @include after-mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        top: calc(var(--header-height) + 55px);
    }

    @include mobile {
        max-width: 100vw;
        width: 100%;
        right: 0;
        z-index: 500;
    }
}
