/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --header-background: #{$white};
    --header-button-bar-color: var(--primary-base-color);
    --header-color: #{$green5};
    --header-icon-color: #{$green5};
    --header-icon-hover-color: #{$green7};
    --header-button-margins: 2rem;
    --header-title-color: var(--primary-dark-color);
    --header-box-shadow: 0px -1px 10px #0000001a;
    --header-contacts-color: #414141;
    --header-icon-stroke-width: 1px;
    --header-search-clear-background: #838383;
    --header-desktop-button-color: var(--primary-base-color);
    --header-height: 55px;
    --header-nav-height: 58px;
    --header-total-height: calc(
        var(--header-height) + var(--offline-notice-height) +
            var(--demo-notice-height) + env(safe-area-inset-top)
    );

    @include desktop {
        --header-dots-color: #000;
    }

    @include after-mobile {
        --header-nav-height: 60px;
        --header-top-menu-height: 30px;
        --header-height: calc(
            var(--header-nav-height) + var(--header-top-menu-height)
        );
    }
}

@mixin nav-button-text {
    cursor: pointer;
    color: var(--header-color);
    font-weight: bold;

    @include desktop-l {
        padding: 1rem 0;
    }
}

@mixin navigationTab-button {
    display: grid;
    text-align: center;

    &:before {
        margin-bottom: 0.6rem;
    }

    &:hover {
        text-decoration: none;
    }
}

@mixin nav-button-common {
    display: grid;
    text-align: center;

    &:before {
        margin-bottom: 0.7rem;
    }

    &:hover {
        color: var(--header-icon-hover-color);
        text-decoration: none;

        @include touchdevice {
            color: var(--header-color);
        }

        &:before {
            color: var(--header-icon-hover-color);

            @include touchdevice {
                color: var(--header-color);
            }
        }
    }

    @include desktop-xl {
        grid-template-columns: auto auto;
        align-items: center;

        &:before {
            margin-bottom: 0;
            margin-right: 0.7rem;
        }
    }

    @include before-desktop-l {
        display: none;
    }
}

@mixin button-invisible {
    opacity: 0;
    padding: 0;
    width: 0;
    pointer-events: none;
    overflow: hidden;
}

@mixin button-visible {
    opacity: 1;
    padding: 7.5px;
    width: 35px;
    pointer-events: all;
    overflow: visible;
}

@mixin logo-visible {
    opacity: 1;
    max-width: 100%;
}

.Header {
    --header-logo-width: 0;
    --header-logo-height: 0;

    position: fixed;
    top: var(--demo-notice-height);
    left: 0;
    width: 100%;
    z-index: 100;
    background-color: var(--header-background);
    padding-top: env(safe-area-inset-top, 0);
    border-bottom: 0;

    &_hasWishlist {
        .SearchField {
            @include desktop {
                padding-left: 1rem;
            }

            @include desktop-xl {
                padding-left: var(--header-button-margins);
            }
        }
    }

    @include desktop-l {
        &-Button_type {
            &_cancel,
            &_back,
            &_close,
            &_share,
            &_clear {
                display: none;
            }
        }
    }

    @at-root .hiddenHeader {
        @include before-desktop {
            /* stylelint-disable-next-line length-zero-no-unit */
            --header-height: 0px;
        }
    }

    @include before-desktop-l {
        box-shadow: var(--header-box-shadow);
        --header-nav-height: 55px;

        .Header-ShipToWrapper,
        .Topbar {
            display: none;
        }

        &_name_default,
        &_name_popup {
            .Topbar {
                display: block;
            }

            .Header-ShipToWrapper {
                display: grid;

                @include mobile {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        &_name_popup {
            --header-background: #{$white};
            z-index: 401;

            .Topbar {
                display: none;
            }

            .Header {
                &-ShipToWrapper {
                    display: none;
                }

                &-Title {
                    font-size: 1.7rem;
                    line-height: 2.3rem;
                    letter-spacing: $letter-spacing-m4;
                }
            }
        }

        &_name_default {
            --header-background: var(--header-background-color);
        }

        &_activeOverlay_SelectLocationPopup {
            --header-background: var(--header-background-color);

            .Header-LogoWrapper {
                opacity: 1;
                max-width: 100%;
            }

            .Header-Button_type_close {
                display: none;
            }
        }
    }

    @include desktop-l {
        --header-background: var(--header-background-color);
    }

    @include print-only {
        box-shadow: none;
        position: static;
    }

    &-Wrapper {
        margin-bottom: var(--header-total-height);
        --header-total-height: 55px;

        @include desktop-l {
            --header-total-height: 145px;
        }

        @include print-only {
            margin-bottom: 0;
            height: auto;
        }

        &_isCheckout {
            @include desktop-l {
                --header-total-height: 80px;
            }
        }
    }

    &_isHiddenOnMobile {
        @include before-desktop {
            pointer-events: none;
            opacity: 0;
        }
    }

    &_name {
        &_pdp {
            .Header-Title {
                @include mobile {
                    text-transform: capitalize;
                }
            }
        }

        &_search {
            .Header-Button_type_back {
                z-index: 1;
                top: 7px;

                &::before {
                    --header-title-color: #{$white};
                }
            }

            .SearchField_isVisible {
                @include mobile {
                    padding-left: 4.6rem;
                }
            }
        }

        &_cart {
            .Header-Button_type_minicart {
                @include desktop {
                    --header-color: var(--header-icon-hover-color)
                }
            }
        }

        &_cart_overlay {
            .Header-Button_type_minicart,
            .Header-MinicartTitle {
                @include after-mobile {
                    --header-color: var(--header-icon-hover-color)
                }
            }

            .Header-MinicartItemCount {
                @include after-mobile {
                    color: #{$white};
                }
            }
        }

        &_menu,
        &_menu_subcategory {
            .Header-Button_type {
                &_close {
                    @include desktop {
                        @include button-visible;
                    }
                }

                &_menu {
                    @include desktop {
                        @include button-invisible;
                    }
                }
            }
        }

        &_customer_account,
        &_customer_sub_account {
            .Header-Button_type_account,
            .Header-MyAccountTitle {
                @include desktop {
                    --header-color: var(--header-desktop-button-color);
                }
            }
        }

        &_checkout {
            .Header-Button,
            .Header-SearchWrapper,
            .SearchField {
                @include desktop {
                    display: none;
                }
            }
        }
    }

    &_isCheckout {
        .Header {
            &-Button_type_account {
                display: block;
                height: 2.4rem;

                &::before {
                    color: var(--primary-dark-color);
                    font-size: 2.2rem;
                    line-height: 2.2rem;
                }
            }

            &-Nav {
                @include desktop {
                    justify-content: space-between;
                }
            }

            &-MyAccount {
                @include before-desktop {
                    position: absolute;
                    right: 10px;
                    top: 16px;
                    width: auto;
                }
            }
        }

        .MyAccountOverlay {
            @include before-desktop {
                top: var(--header-total-height);
                height: calc(100vh - var(--header-total-height));
                position: fixed;
                width: 100vw;
            }
        }
    }

    &-Banner {
        background: var(--primary-base-color);
        color: $white;
        height: 50px;
        line-height: 50px;
        text-align: center;
        display: block;
        z-index: 100;

        strong {
            margin-left: 3px;
        }
    }

    &-TopMenu,
    &-Nav {
        display: flex;
        margin: auto;
        max-width: var(--content-wrapper-width);
    }

    &-TopMenu {
        height: var(--header-top-menu-height);
        justify-content: space-between;
        z-index: 21;
        padding: {
            left: 1.2rem;
            right: 2.4rem;
            top: 1.2rem;
        }

        @include mobile {
            padding: {
                left: 1.4rem;
                right: 2.8rem;
                top: 1.4rem;
            }
        }
    }

    &-Contacts {
        font-size: 1.2rem;
        color: var(--header-contacts-color);

        span:first-child {
            margin-right: 20px;
        }
    }

    &-Nav {
        @include fixed-width;
        flex-wrap: wrap;
        align-items: center;
        height: var(--header-nav-height);
        height: 55px;
        padding: 0 1.2rem;
        z-index: 20;

        @include mobile {
            padding: 0 2rem;
        }

        @include desktop-l {
            grid-template-columns: 235px 1fr auto auto auto auto auto;
            grid-template-areas: "logo search shipto vstore myaccount wishlist minicart";
            align-items: center;
            height: 80px;
            justify-content: space-between;
        }

        @include desktop-xl {
            grid-template-columns: 300px 1fr auto auto auto auto auto;
        }

        @include print-only {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &-Button {
        @include button-invisible;

        cursor: pointer;
        margin: 0;
        height: 35px;
        position: relative;

        @include print-only {
            display: none
        }

        &_isVisible {
            @include before-desktop {
                @include button-visible;

                padding: 0;
            }

            @include before-desktop-l {
                opacity: 1;
                padding: 7.5px;
                width: auto;
                pointer-events: all;
                overflow: visible;
            }
        }

        &_isDisabled {
            pointer-events: none;
            opacity: 0.3;
        }

        &_type {
            &_menu,
            &_account,
            &_minicart {
                @include desktop {
                    @include button-visible;
                }
            }

            &_close {
                @include back-icon;

                &::before {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            &_back {
                @include back-icon;

                &::before {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            &_menu {
                @include navigationTab-button;
                @include menu-icon;

                @include desktop {
                    width: 35px;
                }
            }

            &_account {
                @include before-desktop-l {
                    @include navigationTab-button;
                    @include my-account-icon;
                }

                @include desktop-l {
                    width: 0;
                    height: 0;
                    padding: 0;
                }

                &.Header-Button_isVisible {
                    @include desktop-l {
                        overflow: hidden;
                    }
                }
            }

            &_minicart {
                @include desktop {
                    cursor: default;
                    height: auto;
                    width: auto;
                    order: 6;
                }

                @include before-desktop-l {
                    @include mini-cart-icon;
                    @include navigationTab-button;
                }

                @include desktop-xl {
                    grid-area: minicart;
                    padding: 0;
                }
            }

            &_ordertracker {
                @include before-desktop-l {
                    display: grid;
                    text-align: center;

                    @include order-tracker-icon;

                    &::before {
                        margin-bottom: 5px;
                    }
                }
            }

            &_searchClear {
                @include clear-search-button;
            }

            &_clear {
                @include icon($icon-delete, before, 2rem, $grey12);

                &::before {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            &_edit {
                text-transform: uppercase;

                &.Header-Button_isVisible {
                    width: auto;
                }
            }

            &_share {
                @include share-button-wishlist;

                &.Header-Button_isVisible {
                    width: 19px;
                    margin-left: 0;
                }
            }

            &_home {
                @include navigationTab-button;
                @include home-icon;

                @include before-desktop-l {
                    padding: 0;
                    overflow: visible;
                    opacity: 1;
                }
            }

            &_ok,
            &_cancel {
                font-size: 1.4rem;

                &.Header-Button_isVisible {
                    width: auto;
                }
            }

            &_compare {
                @include button-visible;

                padding: 0;
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    &-CompareButtonWrapper {
        border-right: 2px dotted var(--header-dots-color);
        margin: 0 20px 0 7px;
        padding-right: 20px;
    }

    &-CompareIcon {
        @include compare-icon(var(--header-color));
    }

    &-MyAccount {
        grid-area: myaccount;
        padding-left: var(--header-button-margins);

        @include desktop {
            order: 4;
            padding-left: 1rem;
        }

        @include desktop-xl {
            padding-left: 0;
        }

        &Wrapper {
            align-items: center;
            display: flex;
        }

        &Title {
            cursor: pointer;
            padding-right: 0.48rem;
            color: var(--header-color);
            @include nav-button-text;
            @include nav-button-common;
            @include my-account-icon;

            @include mobile {
                padding-right: 0.56rem;
                display: none;
            }
        }
    }

    &-Minicart {
        &ButtonWrapper {
            @include nav-button-text;
            @include nav-button-common;
            align-items: center;
            cursor: pointer;
            height: auto;
            padding-right: 2.16rem;

            &:hover {
                .Header-MinicartTitle {
                    color: var(--header-icon-hover-color);

                    @include touchdevice {
                        color: var(--header-color);
                    }
                }

                .Header-MinicartIcon {
                    &:before {
                        color: var(--header-icon-hover-color);

                        @include touchdevice {
                            color: var(--header-color);
                        }
                    }
                }
            }

            @include mobile {
                margin-left: 2.8rem;
                padding-right: 2.52rem;
            }

            @include tablet-portrait {
                margin-left: 0;
            }
        }

        &Title {
            color: var(--header-color);
            margin-right: 0.84rem;
            order: 2;

            @include mobile {
                margin-right: 0.98rem;
            }
        }

        &Icon {
            @include mini-cart-icon;
            
            order: 1;
            margin-bottom: 0.7rem;
            top: 0;
            width: auto;

            @include desktop {
                height: 20px;
                width: 25px;
                top: 1px;
            }

            @include desktop-xl {
                margin-bottom: 0;
                margin-right: 0.7rem;
            }
        }

        &ItemCount {
            line-height: 13px;
            min-width: 14px;
            padding-left: 5px;
            background: $green4;
            border-radius: 10px;
            color: $white;
            display: inline-block;
            font-size: 1.2rem;
            padding: 2px 5px 2px;
            position: absolute;
            transform: translateX(3px);
            z-index: 2;

            @include before-desktop-l {
                left: 50%;
                top: -6px;
            }

            @include desktop-l {
                left: 13px;
                top: -1px;
            }
        }
    }

    &-LogoWrapper {
        --header-logo-height: 33px;
        --header-logo-width: 177px;

        position: relative;
        height: var(--header-logo-height);
        width: var(--header-logo-width);
        max-width: 0;
        left: 0;
        right: 0;
        margin: auto;
        top: 0;
        bottom: 0;
        opacity: 0;
        overflow: hidden;
        margin: 9px auto 13px;

        transition-property: opacity;
        transition-duration: 200ms;

        @include print-only {
            display: none;
        }

        .a {
            fill: #f8c7a8;
        }
        .b {
            fill: #f2a749;
        }
        .c {
            fill: #4a9539;
        }
        .d {
            fill: #de1322;
        }
        .e {
            fill: #301e18;
        }
        .f {
            fill: #d69b78;
        }

        @include desktop {
            --header-logo-width: 215px;

            width: 215px;
            height: inherit;
            grid-area: logo;
            display: block;
            margin: auto 0;
            padding: 13px 0;
            order: 0;
        }

        @include before-desktop-l {
            opacity: 0;

            &_isVisible {
                opacity: 1;
            }
        }

        @include desktop-xl {
            --header-logo-width: 278px;

            width: 278px;
            padding-left: 0;
        }

        @include desktop-l {
            @include logo-visible;
        }

        &_isVisible {
            @include logo-visible;
        }

        .Image-Image {
            object-fit: cover;

            @include desktop-xl {
                object-position: 50% 50%;
            }
        }
    }

    &-Title {
        @include sub-heading-1;

        opacity: 0;
        max-width: 0;
        transition-property: opacity;
        transition-duration: 200ms;
        text-align: center;
        color: var(--header-title-color);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        pointer-events: none;
        position: absolute;
        margin: auto;
        width: 100%;
        height: 2.3rem;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        &_isVisible {
            @include before-desktop-l {
                opacity: 1;
                max-width: calc(100% - 90px);
            }

            @include print-only {
                font-size: 24px !important;
                line-height: 32px !important;
                height: 32px !important;
                max-width: 100%;
                position: static;
                text-align: left;
            }
        }
    }

    a {
        &:hover,
        &:focus {
            @include before-desktop {
                text-decoration: none;
            }
        }
    }

    &-ShipTo {
        &Inner {
            @include mobile {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: -1px;
            }

            .Header-Placeholder {
                color: var(--header-color);
                font-weight: 700;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-transform: capitalize;
            }
        }

        &Wrapper {
            grid-area: shipto;
            display: grid;
            align-items: center;
            grid-template-areas: "location-icon shipto location";
            @include location-picker-icon;

            &:before {
                grid-area: location-icon;
                margin-right: 1rem;

                @include mobile {
                    margin-right: 0.4rem;
                    color: $white;
                }
            }

            @include before-desktop {
                width: calc(100% + 40px);
                margin: 0 -2rem;
            }

            @include desktop {
                order: 2;
                padding-left: 1rem;
            }

            @include desktop-xl {
                padding-left: 0;
            }

            @include before-desktop-l {
                background-color: var(--header-button-bar-color);
                height: 2.4rem;
                justify-content: center;
            }

            @include desktop-l {
                text-align: left;
                grid-template-areas:
                    "location-icon shipto"
                    "location-icon location";
                grid-template-columns: 26px auto;

                &:hover {
                    .Header-ShipToTitle {
                        color: var(--header-icon-hover-color);

                        @include touchdevice {
                            color: var(--header-color);
                        }
                    }
                }
            }
        }

        &SubTitle {
            grid-area: shipto;
            color: $white;
            font-size: 1.3rem;
            text-align: left;

            @include before-desktop-l {
                padding-right: 0.5rem;
                width: 46px;
            }

            @include desktop-l {
                color: $grey4;
            }
        }

        &Title {
            grid-area: location;
            @include nav-button-text;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-transform: capitalize;

            @include mobile {
                width: 100px;
                color: $white;
            }

            @include desktop-l {
                padding: 0;
                max-width: 113px;
            }
        }

        &Inner {
            @include desktop-l {
                display: flex;
                flex-direction: column;
                line-height: 1.8rem;
            }
        }
    }

    &-WishList {
        grid-area: wishlist;
        @include nav-button-text;
        @include wishlist-icon;
        margin-left: 0;
        padding-left: var(--header-button-margins);
        @include nav-button-common;

        @include desktop {
            order: 5;
            padding-left: 1rem;
        }

        @include desktop-xl {
            padding-left: 0;
        }
    }

    &-VirtualStoreTLink {
        grid-area: vstore;
        @include nav-button-text;
        @include virtual-icon;
        padding-left: var(--header-button-margins);
        @include nav-button-common;
        display: none;

        @include desktop {
            order: 3;
            padding-left: 1rem;
        }

        @include desktop-xl {
            padding-left: 0;
        }
    }

    &-CompareButtonWrapper {
        display: none;
    }

    @include before-desktop-l {
        &-Button {
            height: 0;

            &_isVisible {
                height: 3.5rem;
                padding: 0;
            }
        }
    }

    &_name_order_fail {
        @include mobile {
            .Header-LogoWrapper {
                display: none;
            }
        }
    }

    &_activeOverlay {
        &_SelectLocationPopup {
            --header-background: var(--header-background-color);

            z-index: 100;

            .Topbar {
                @include mobile {
                    display: block;
                }
            }

            .Header-Nav {
                .LogoWrapper {
                    opacity: 1;
                    max-width: 100%;
                }

                .Header-ShipToWrapper {
                    display: flex;
                }
            }
        }
    }

    // Checkout Page customization 
    &-Wrapper_isCallbackStep,
    &-Wrapper_isShipingStep,
    &-Wrapper_isBillingStep,
    &-Wrapper_isSuccessStep {
        .Topbar,
        .Header-VirtualStoreTLink,
        .Header-ShipToWrapper,
        .Header-WishList,
        .Menu-MenuWrapper {
            display: none;
        }

        .MyAccountOverlay {
            @include desktop {
                right: 0;
            }

            @include desktop-xxl {
                right: 11.3rem;
            }
        }

        .Header {
            &-MyAccount  {
                @include desktop-xxl {
                    padding-right: 112px;
                }
            }

            &-CheckoutStep {
                width: 260px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                position: absolute;
                display: inline-block;

                @include mobile {
                    display: none;
                }

                li {
                    width: 130px;
                    display: inline-block;
                    position: relative;
                    text-align: center;
                    vertical-align: top;
                    padding-left: 0;
                    margin-bottom: 0;

                    &::before {
                        position: static;
                        padding-right: 0;
                        display: inline-block;
                        width: 100%;
                        width: 26px;
                        height: 26px;
                        border: 2px solid $green4;
                        border-radius: 50%;
                        margin-bottom: 9px;
                    }

                    span {
                        display: inline-block;
                        width: 100%;
                        font-size: 1.2rem;
                        font-weight: $font-weight-bold;
                        letter-spacing: $letter-spacing-s3;
                        color: $grey5;
                        text-transform: uppercase;
                    }

                    &:first-of-type {
                        &::after {
                            background: $grey9;
                            top: 15px;
                            content: '';
                            width: 83px;
                            height: 2px;
                            right: -32%;
                            position: absolute;
                        }
                    }

                    &.Header-ShippingStep {
                        @include shipping-step-icon;

                        &::before {
                            line-height: 25px;
                        }
        
                        &_isActive {
                            &::before {
                                background-color: $green4;
                                border-color: $green4;
                                color: $white;
                            }

                            span {
                                color: $green5;
                            }
                        }
                    }
        
                    &.Header-BillingStep {
                        @include billing-step-icon;

                        &::before {
                            line-height: 26px;
                        }

                        &_isActive {
                            &::before {
                                background-color: $green4;
                                border-color: $green4;
                                color: $white;
                            }

                            span {
                                color: $green5;
                            }
                        }
                    }
                }
            }
        }
    }

    &-Wrapper_isCallbackStep,
    &-Wrapper_isSuccessStep {
        .Header {
            .SearchField,
            .Header-Button_type_minicart {
                display: none;
            }

            .Header-MyAccount {
                display: none;
            }
        }
    }
}
