/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --homepage-slider-color: #{$white};
    --homepage-slider-slider-crumb-bg-color: #{$white};
    --homepage-slider-slider-crumb-active-bg-color: var(--primary-light-color);
    --homepage-slider-arrow-bg-color: #{$grey2};
    --homepage-slider-arrow-hover-bg-color: #{$default-primary-dark-color};
}

.homepage-slider {
    h1 {
        font-size: 6.2rem;
        text-transform: uppercase;
        color: var(--homepage-slider-color);
        margin-bottom: 3rem;

        @include mobile {

            font-size: 3rem;
            margin-bottom: 1.4rem;
        }
    }
}

.Slider {
    .Slider-Crumbs {
        grid-gap: 20px;
        bottom: 2.4rem;

        @include mobile {
            bottom: 2.8rem;
        }
    }

    .Slider-Image {
        width: 20px;
        height: 7px;
    }

    .Slider-Crumb {
        width: 100%;
        border-radius: 10px;
        border: none;
        background: var(--homepage-slider-slider-crumb-bg-color);
    }

    .Slider-Crumb_isActive {
        background: var(--homepage-slider-slider-crumb-active-bg-color);
    }

    .SliderWidget-Figcaption {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0 10px;

        @include desktop {
            max-width: $layout__max-width;
            padding: 0 100px;
        }
    }

    .Slider-Arrows {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;

        @include desktop {
            max-width: $layout__max-width;
        }

        @include mobile {
            display: none;
        }

        .Slider-Left-Arrow,
        .Slider-Right-Arrow {
            top: 50%;
            position: absolute;
            background: var(--homepage-slider-arrow-bg-color);
            padding: 0;
            border-radius: 50%;
            font-size: 0;
            width: 45px;
            height: 45px;

            &:hover {
                @include desktop {
                    background: var(--homepage-slider-arrow-hover-bg-color);

                    &::after {
                        color: $white;
                    }
                }

                @include touchdevice {
                    background: var(--homepage-slider-arrow-bg-color);

                    &::after {
                        color: $black;
                    }
                }
            }

            &_isDisable {
                opacity: 0.5;

                &:hover {
                    background: var(--homepage-slider-arrow-bg-color);

                    &::after {
                        color: var(--primary-dark-color);
                    }
                }
            }
        }

        .Slider-Left-Arrow {
            @include slider-left-icon;

            left: 20px;

            &:after {
                margin-right: 3px;
            }
        }

        .Slider-Right-Arrow {
            @include slider-right-icon;

            right: 20px;

            &:after {
                margin-left: 3px;
            }
        }
    }
}
