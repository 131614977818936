/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --table-secondary-color: var(--secondary-divider-color);
}

table {
    thead {
        tr {
            background-color: var(--table-secondary-color);
        }
    }

    tbody {
        tr {
            border-bottom: 1px solid var(--table-secondary-color);
        }
    }

    td,
    th {
        font-size: 1.4rem;
        line-height: 1.9rem;
        letter-spacing: $letter-spacing-m;
        padding: 15px;
        text-align: left;
        min-width: 12rem;

        @include mobile {
            min-width: 14rem;
        }
    }

    th {
        text-transform: capitalize;
    }
}

.Table-Wrapper {
    max-width: 100%;
    overflow-x: scroll;
}
