/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

 .kemanapwa-home-page-top-categories {
    margin-top: 20px;
    margin-bottom: 20px;

    &.fixed-width {
        min-height: 327px;

        @include desktop {
            min-height: 392px
        }

        @include desktop-xl {
            min-height: 344px
        }
    }

    @include desktop {
        margin-top: 40px;
        margin-bottom: 20px;
    }

     h2 {
         @include h4;

         width: 100%;
         text-align: center;

         @include mobile {
            min-height: 27px;
        }

        @include desktop {
           min-height: 38px;
        }
     }

     .Home-Categories {
        display: flex;
        flex-wrap: wrap;
        margin-left: -5px;
        margin-right: -5px;

        @include mobile {
           flex-wrap: wrap;
        }

        @include desktop {
            margin-left: -10px;
            margin-right: -10px;
        }

         &-Item {
            width: 50%;
            margin-bottom: 10px;
            padding: 0 5px;
            display: flex;

            @include desktop {
                width: 25%;
                margin-bottom: 20px;
                padding: 0 10px;
            }

            &-Container {
                overflow: hidden;
                display: flex;
                width: 100%;
                background: $white;
                border-radius: 5px;
                transition: $transition1;
                position: relative;

                &:hover {
                    @include desktop-l {
                        box-shadow: $box-shadow2;
                    }

                    @include touchdevice {
                        box-shadow: none;
                    }
                }

                a {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    left: 0;
                    top: 0;
                    z-index: 1;  
                }
            }

            &-Left {
                width: calc(100% - 60px);
                padding: 10px 0 10px 10px;

                @include desktop-l {
                    width: calc(100% - 100px);
                    padding: 20px 0 15px 15px;
                }

                .SubTitle-Primary {
                    margin-bottom: 10px;

                    @include mobile {
                        font-size: 1.4rem;
                        margin-bottom: 0;
                        line-height: 1.9rem;
                    }
                }

                p {
                    @include mobile {
                        display: none;
                    }
                }
            }

            &-Right {
                width: 60px;
                height: 60px;

                @include desktop-l {
                    width: 100px;
                    height: 100px;
                }
            }
         }
     }
 }
