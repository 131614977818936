/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
.SelectLocationPopup {
    align-items: center;

    &_isVisible {
        width: 100%;
        height: 100vh;
    }

    &-title {
        display: inline-block;
        width: 100%;
        text-align: center;
        margin-bottom: 0.7rem;
        font-size: $h5-font-size-desktop;
        line-height: $h5-font-line-height-desktop;
        letter-spacing: $h5-letter-spacing;
    }

    &-Submit{
        width: 100%;
        margin-top: 20px;
    }
    .Popup {
        &-Content {
            border-radius: 5px;
            min-width: var(--popup-location-min-width);
            overflow-y: visible;
    
            @include mobile {
                min-width: var(--popup-location-min-width);
                width: var(--popup-location-min-width);
            }
        }

        &-CloseBtn{
            display: none;
        }
    }
}
