/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --product-list-load-button-background: var(--secondary-base-color);
}

.ProductList {
    @include after-mobile {
        grid-column: 2;
        padding-bottom: 2.4rem;
    }

    &-ProductsMissing {
        padding: 1rem 2rem 3rem;
        text-align: center;

        @include desktop {
            padding: 10rem 8rem 76px;
        }

        p {
            --body-paragraph-color: var(--content-paragraph-color);

            margin-top: 2rem;
        }
    }

    &-More {
        min-height: 3.6rem;

        @include mobile {
            min-height: 4.2rem;
        }
    }

    &-LoadButton {
        cursor: pointer;
        padding: 0 0 2rem;
        text-align: center;
    }
}
