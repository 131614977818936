/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

 .style-guide {
     &-buttons {
         margin-top: 20px;

         &-primary {
             margin-right: 20px;
         }
     }

     &-link {
        margin-top: 20px;

         &-primary {
            margin-right: 20px;
         }
     }
 }
