/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
@mixin button-disabled {
    opacity: .25;
    cursor: not-allowed;
}

@mixin button {
    text-decoration: none; // because we never need underlines in buttons
    display: inline-block;
    font-family: $base-font-family;
    font-weight: var(--button-font-weight);
    font-size: var(--button-font-size);
    text-transform: var(--button-text-transform);
    letter-spacing: var(--button-letter-space);
    line-height: var(--button-line-height);
    height: var(--button-line-height);
    padding: var(--button-padding);
    color: var(--button-color);
    background-color: var(--button-background);
    transition-property: background-color, color, border;
    will-change: background-color, color, border;
    transition-timing-function: ease-out;
    transition-duration: .25s;
    border-width: var(--button-border-width);
    border-style: solid;
    border-color: var(--button-border);
    cursor: pointer;
    border-radius: var(--button-radius);
    text-align: var(--button-text-align);

    @include desktop-xl {
        &:hover {
            text-decoration: none;
            border-color: var(--button-hover-border);
            background-color: var(--button-hover-background);
            color: var(--button-hover-color);
        }
    }

    @include touchdevice {
        &:hover {
            text-decoration: none;
            border-color: var(--button-border);
            background-color: var(--button-background);
            color: var(--button-color);
        }
    }

    &[disabled] {
        @include button-disabled();
    }

    &_isHollow {
        border-color: var(--button-border);
        color: var(--button-primary-revert-color);
        background: var(--button-secondary-background);

        @include desktop-xl {
            &:hover {
                border-color: var(--button-primary-revert-hover-border);
                background-color: var(--button-primary-revert-hover-background);
                color: var(--button-primary-revert-hover-color);
            }
        }

        @include touchdevice {
            &:hover {
                border-color: var(--button-border);
                color: var(--button-primary-revert-color);
                background: var(--button-secondary-background);
            }
        }    
    }

    &_likeLink {
        --button-border: transparent;
        --button-background: transparent;
        --button-hover-border: transparent;
        --button-hover-background: transparent;
        --button-line-height: normal;

        color: var(--button-like-link-color);
        font-size: var(--button-like-link-font-size);
        font-weight: var(--button-like-link-font-weight);
        letter-spacing: var(--button-like-link-letter-spacing);
        text-transform: none;
        padding: 0;
        text-align: left;

        @include desktop-xl {
            &:hover {
                color: var(--button-like-link-hover-color);
                text-decoration: var(--button-like-link-hover-text-decoration);
            }

            &:focus {
                text-decoration: var(--button-like-link-hover-text-decoration);
            }
        }

        @include touchdevice {
            &:hover,
            &:focus {
                color: var(--button-like-link-color);
                text-decoration: none;
            }
        }

        &_Primary {
            color: var(--button-like-link-primary-color);

            @include desktop-xl {
                &:hover {
                    color: var(--button-like-link-primary-hover-color);
                    text-decoration: var(--button-like-link-primary-hover-text-decoration);
                }

                &:focus {
                    text-decoration: var(--button-like-link-primary-hover-text-decoration);
                }
            }

            @include touchdevice {
                &:hover,
                &:focus {
                    color: var(--button-like-link-primary-color);
                    text-decoration: none;
                }
            }
        }

        &_Secondary {
            color: var(--button-like-link-secondary-color);

            @include desktop-xl {
                &:hover {
                    color: var(--button-like-link-secondary-hover-color);
                    text-decoration: var(--button-like-link-secondary-hover-text-decoration);
                }

                &:focus {
                    text-decoration: var(--button-like-link-secondary-hover-text-decoration);
                }
            }

            @include touchdevice {
                &:hover,
                &:focus {
                    color: var(--button-like-link-secondary-color);
                    text-decoration: none;
                }
            }
        }
    }

    &_PrimaryRevert {
        border-color: var(--button-primary-revert-border);
        background-color: var(--button-primary-revert-background);
        color: var(--button-primary-revert-color);

        @include desktop-xl {
            &:hover {
                border-color: var(--button-primary-revert-hover-border);
                background-color: var(--button-primary-revert-hover-background);
                color: var(--button-primary-revert-hover-color);
            }
        }

        @include touchdevice {
            &:hover {
                border-color: var(--button-primary-revert-border);
                background-color: var(--button-primary-revert-background);
                color: var(--button-primary-revert-color);
            }
        }
    }

    &_Secondary {
        border-color: var(--button-secondary-border);
        background-color: var(--button-secondary-background);
        color: var(--button-secondary-color);

        @include desktop-xl {
            &:hover {
                border-color: var(--button-secondary-hover-border);
                background-color: var(--button-secondary-hover-background);
                color: var(--button-secondary-hover-color);
            }
        }

        @include touchdevice {
            &:hover {
                border-color: var(--button-secondary-border);
                background-color: var(--button-secondary-background);
                color: var(--button-secondary-color);
            }
        }
    }

    &_SecondaryRevert {
        border-color: var(--button-secondary-revert-border);
        background-color: var(--button-secondary-revert-background);
        color: var(--button-secondary-revert-color);

        @include desktop-xl {
            &:hover {
                border-color: var(--button-secondary-revert-hover-border);
                background-color: var(--button-secondary-revert-hover-background);
                color: var(--button-secondary-revert-hover-color);
            }
        }

        @include touchdevice {
            &:hover {
                border-color: var(--button-secondary-revert-border);
                background-color: var(--button-secondary-revert-background);
                color: var(--button-secondary-revert-color);
            }
        }
    }

    &_PrimaryOrange {
        border-color: var(--primary-base-color);
        background-color: var(--primary-base-color);
        color: var(--button-color);

        @include desktop-xl {
            &:hover {
                border-color: var(--secondary-base-color);
                background-color: var(--secondary-base-color);
                color: var(--button-hover-color);
            }
        }

        @include touchdevice {
            &:hover {
                border-color: var(--primary-base-color);
                background-color: var(--primary-base-color);
                color: var(--button-color);
            }
        }
    }
}
