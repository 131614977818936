/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
.Topbar {
    background-color: var(--top-bar-background-color);
    font-size: 1.1rem;
    line-height: 1.5rem;
    letter-spacing: .33px;
    color: $white;
    text-align: center;
    padding: .4rem 0;
    height: 23px;

    @include desktop {
        font-size: 1.3rem;
        line-height: 1.8rem;
        padding: .6rem 0;
        height: 30px;
    }

    &-Wrapper {
        max-width: var(--content-wrapper-width);
        width: 100%;
        margin: auto;
    }

    strong {
        font-weight: $font-weight-black;
    }

    &-OrderTracker {
        position: absolute;
        right: 1rem;
        top: 0;

        @include mobile {
            display: none;
        }

        a {
            --link-color: #{$white};

            font-weight: $font-weight-bold;
        }
    }
}