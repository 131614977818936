/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --price-color: var(--primary-dark-color);
    --price-with-discount-color: var(--primary-light-color);
    --price-discount-color: #{$grey7};
}

.ProductPrice {
    color: var(--price-color);
    font-weight: $font-weight-black;
    font-size: 14px;
    line-height: 19px;
    vertical-align: middle;
    margin-bottom: 0;
    letter-spacing: $letter-spacing-m;

    &_hasDiscount {
        color: var(--price-with-discount-color);
    }

    ins {
        text-decoration: none;
    }

    del {
        font-size: 14px;
        font-weight: $font-weight-regular;
        display: inline-block;
        margin-left: 5px;
    }

    &-HighPrice {
        // padding-left: .1em;
        color: var(--price-discount-color);
    }

    &-Placeholder {
        &::after {
            content: 'aaaaa';
        }
    }

    &-SubPrice {
        display: block;
        font-weight: 500;
        font-size: .5em;
    }
}

.ProductActions {
    .ProductPrice {
        &-HighPrice {
            font-size: 2rem;
            margin-left: 10px;
        }

        &_hasDiscount {
            ins {
                letter-spacing: $letter-spacing-s;
                font-size: 2.8rem;
            }
        }
    }
}
