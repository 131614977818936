/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
 :root {
    --button-disabled-color: var(--disabled-color);
    --button-disabled-background-color: var(--disabled-background-color);
 }

.AddToCart {
    vertical-align: middle;

    span {
        display: block;
        opacity: 1;
        transform: translateY(0);
        transition-property: transform, opacity;
        will-change: transform, opacity;
        transition-timing-function: ease-in;
        transition-duration: .25s;

        @include mobile {
            margin-top: -1px;
        }

        &:last-child {
            width: 100%;
            left: 0;
            opacity: 0;
            position: absolute;
            transform: translateY(0);
        }
    }

    &:disabled {
        --button-background: var(--button-disabled-background-color);
        --button-border: var(--button-disabled-background-color);
        --button-color: var(--button-disabled-color);
        
        opacity: 1;
    }

    &_isPlaceholder {
        height: 4.8rem;
        display: inline-block;
        padding: var(--button-padding);
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        animation: var(--placeholder-animation);
        will-change: background-position;

        @include mobile {
            height: 5.6rem;
        }
    }

    &_isLoading {
        --button-background: var(--button-disabled-background-color) !important;
        --button-border: var(--button-disabled-background-color) !important;
        --button-color: var(--button-disabled-color) !important;
        --button-hover-background: var(--button-disabled-background-color) !important;
        --button-hover-border: var(--button-disabled-background-color) !important;

        span {
            &:first-child {
                opacity: 0;
                transform: translateY(-50%);
            }

            &:last-child {
                opacity: 1;
                transform: translateY(-100%);
            }
        }

        &:active {
            span {
                &:last-child {
                    opacity: .9;
                    transform: translateY(-120%);
                }
            }
        }
    }
}
