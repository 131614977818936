/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 :root {
    --navigation-tabs-height: 55px;
    --navigation-tabs-background: var(--primary-base-color);
    --navigation-tabs-color: var(--header-color);
    --navigation-item-color: #{$white};
}

%navigationTab-button {
    display: grid;
    text-align: center;

    &:before {
        margin-bottom: .7rem;
    }

    &:hover {
        color: var(--header-icon-hover-color);
        text-decoration: none;

        &:before {
            color: var(--header-icon-hover-color);
        }
    }
}

.NavigationTabs {
    --header-background: var(--navigation-tabs-background);
    --header-color: var(--navigation-tabs-color);
    --header-icon-stroke-width: 2px;

    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 101;
    background-color: var(--header-background);
    transform: translateY(0);
    transition: transform 200ms cubic-bezier(.47, 0, .745, .715);

    .hideOnScroll & {
        transform: translateY(100%);
    }

    @include desktop-l {
        display: none;
    }

    @include print-only {
        display: none;
    }

    &_isHidden {
        @include desktop-l {
            display: none;
        }

        @at-root .hiddenNavigationTabs {
            --navigation-tabs-height: env(safe-area-inset-bottom);

            .NavigationTabs-Nav {
                display: none;
            }
        }
    }

    &-Nav {
        height: var(--navigation-tabs-height);
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 0 1.2rem;
        z-index: 20;
        max-width: var(--content-wrapper-width);
        margin: auto;

        @include mobile {
            padding: 0 1.4rem;
        }
    }

    &-Button {
        flex-grow: 1;
        height: 100%;
        display: inherit;
        justify-content: center;
        align-items: center;

        .Header-Button_isVisible {
            padding: 0;
        }
    }

    &-Icon {
        span {
            color: var(--navigation-item-color);
            font-weight: bold;
            font-size: 1.1rem;
            letter-spacing: $letter-spacing-m3;

            &.Header-MinicartItemCount {
                color: $white;
            }
        }

        &_isActive {
            --header-color: #{$green5;};
            --navigation-item-color: #{$green5;};
            
            &::before {
                color: $green5;
            }
        }
    }
}
