/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

.kemanapwa-best-selling-products,
.kemanapwa-home-page-new-arrivals,
.kemanapwa-home-page-all-day-exclusives {
    h2 {
        @include h4;

         width: 100%;
         text-align: center;

         @include mobile {
             min-height: 27px;
         }

         @include desktop {
            min-height: 38px;
         }
    }

    .ProductListPage {
        @include mobile {
            padding-left: .5rem;
            padding-right: .5rem;
        }
    }
}
