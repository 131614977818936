/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
.InstallPromptIOS {
    background: var(--header-button-bar-color);
    padding: 1rem 4.5rem 1rem 1rem;
    min-height: 50px;

    &-Heading {
        margin: 0 0 .4rem;
        text-align: left;
        width: 100%;
        font-size: 1.2rem;
        letter-spacing: $letter-spacing-s3;
        font-weight: $font-weight-bold;
        color: $white;
    }

    &-Content {
        color: $white;
        align-items: center;
        display: flex;
        justify-content: left;
        width: 100%;
        font-size: 1rem;
        letter-spacing: 0.25px;
    }

    &-Left {
        width: 100%;
    }

    &-Close {
        position: absolute;
        right: 10px;
        top: 14px;
        width: 16px;
        height: 20px;

        @include close-button;

        &::before,
        &::after {
            height: 22px;
            top: 0;
            background-color: $white;
        }

        span {
            display: none
        }
    }

    &-Plus {
        @include ios-plus;
        transform: scale(.9);
        transform-origin: 50% 50%;
    }

    &-Share {
        @include ios-share;
        margin: 0 .3rem;
    }

    p {
        line-height: 1;
    }
}
