/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
.FieldDate {
    &-Container {
        .react-datepicker {
            font-family: $base-font-family;
            float: right;
            border-radius: 0;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            border: 0;

            &__month-container {
                width: 280px;
            }

            &__triangle {
                display: none;
            }

            &__input-container {
                @include calendar-icon;

                input {
                    --input-background-color: transparent;
                }

                &::before {
                    font-size: 18px;
                    line-height: 18px;
                    position: absolute;
                    right: 12px;
                    top: 10px;
                }
            }

            &-popper {
                right: 0 !important;

                &[data-placement^=top] {
                    padding-bottom: 5px;
                }

                &[data-placement^=bottom] {
                    padding-top: 5px;
                }
            }

            &__header {
                background: transparent;
                color: $white;
                padding: 0;
                border: 0;
                border-radius: 0;
            }

            &__day-names {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                background: $grey20;
                margin-bottom: 0;
            }

            &__month {
                background: $grey2;
                margin: 0;
            }

            &__week {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }

            &__day-name,
            &__day,
            &__time-name {
                color: var(--primary-dark-color);
                width: 30px;
                height: 30px;
                line-height: 30px;
                font-size: 10px;
                margin: 0;
            }

            &__day-name {
                font-size: 12px;
                text-transform: uppercase;
            }

            &__day {
                border-radius: 50%;
                border: 1px solid transparent;

                &:hover {
                    @include desktop {
                        border-color: var(--primary-light-color);
                    }
                }

                &--outside-month, &--disabled {
                    color: $grey21;
                }

                &--selected {
                    color: $white;
                    background-color: var(--primary-base-color);
                    border-color: var(--primary-base-color);
                }

                &--keyboard-selected {
                    color: $white;
                    background-color: var(--primary-base-color);
                    border-color: var(--primary-base-color);
                }
            }
        }

        &_isCalendarOpen {
            .react-datepicker__input-container {
                &:before {
                    color: var(--primary-base-color);
                 }
            }
        }
    }

    &-HeaderWrapper {
        &Top {
            background-color: $grey17;
            padding: 8px 12px;
        }

        &Buttons {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    &-BackwardButton {
        @include prev-icon;

        span {
            display: none;
        }
    }

    &-ForwardButton {
        @include next-icon;

        span {
            display: none;
        }
    }

    &-MonthandYear {
        color: $grey20;
        font-size: 12px;
        line-height: 16px;
    }

    &-Month,
    &-Year {
        position: relative;
        margin: 0 5px;

        select {
            --input-line-height: 25px;

            font-size: 1rem;
            letter-spacing: 0.25px;
        }

        &::after {
            content: '';
            display: block;
            width: 5px;
            height: 5px;
            transform: translateY(-2px) rotate(45deg);
            pointer-events: none;
            position: absolute;
            right: 10px;
            bottom: 0;
            top: 0;
            margin: auto;
            border-style: solid;
            border-color: #{$grey6};
            border-width: 0 1px 1px 0;
            z-index: 1;
        }
    }

    &-Month {
        select {
            --input-padding: 0 1.5rem 0 1rem;
        }
    }

    &-Year {
        select {
            --input-padding: 0 2.5rem 0 1rem;
        }
    }

    &-Bottom {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 5px;
    }
}
