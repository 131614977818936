/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
.MyAccountCompleteProfilePopup {
  .Popup {
    &-Heading {
      padding: 0 25px;
    }

    &-Container {
      text-align: center;
    }
  }

  &-Content {
    padding-bottom: 20px;
  }

  &-Button {
    width: 100%;
    text-align: center;

    p {
      a {
        --link-color: var(--primary-base-color);
      }
    }
  }
}
