/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
:root {
    --install-prompt-android-background: var(--header-button-bar-color);
}

.InstallPromptAndroid {
    --button-hover-background: var(--homepage-button-color);
    --button-hover-color: var(--homepage-button-background);
    --button-hover-border: transparent;
    --button-border-width: 0;

    background: var(--install-prompt-android-background);
    padding: .5rem 4.5rem .5rem 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    
    &-Heading {
        margin: 0 0 .4rem;
        text-align: left;
        width: 100%;
        font-size: 1.2rem;
        letter-spacing: $letter-spacing-s3;
        font-weight: $font-weight-bold;
        color: $white;
    }

    &-Content {
        color: $white;
        align-items: center;
        display: flex;
        justify-content: left;
        width: 100%;
        font-size: 1rem;
        letter-spacing: 0.25px;
    }

    &-Left {
        width: calc(100% - 112px);
    }

    &-Close {
        position: absolute;
        right: 10px;
        top: 14px;
        width: 16px;
        height: 20px;

        @include close-button;

        &::before,
        &::after {
            height: 22px;
            top: 0;
            background-color: $white;
        }

        span {
            display: none
        }
    }

    &-Button {
        --button-padding: 0 24px;
        --button-line-height: 4rem;
        --button-font-size: 1.2rem;
        --button-letter-space: $letter-spacing-m4;

        width: max-content;
    }

    p {
        line-height: 1;
    }
}
