/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

.fixed-width {
    @include fixed-width;
}

.Desktop-only {
    @include mobile {
        display: none;
    }
}

.Mobile-only {
    display: none;

    @include mobile {
        display: inline-block;
    }
}

 //  HTML Disclosure Summary element
details {
    summary {
        padding-bottom: 15px;
        padding-top: 15px;
        border-top: 1px solid var(--primary-divider-color);
        cursor: pointer;

        @include icon($icon-angle-down, after, 6.71px, var(--primary-dark-color));

        &:after {
            position: absolute;
            right: 0;
            top: 20px;
        }

        &.SubTitle-Secondary {
            @include mobile {
                margin: 0;
            }
        }

        &::-webkit-details-marker {
            display: none;
        }
    }

    &[open] {
        @include mobile {
            margin-bottom: 15px;
        }

        summary {
            @include icon($icon-angle-up, after, 6.71px, var(--primary-dark-color));
        }
    }
}
