/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Yehan Virantha <yviranta@kemana.com>
 * http://www.kemana.com
 *
 */

.kemanapwa-home-page-spot-banner {
    padding-bottom: 40px;
    min-height: 410px;

    @include mobile {
        padding-bottom: 30px;
    }

    @media (min-width: 768px) {
        min-height: 690px;
    }

    @include desktop {
        min-height: 295px;
     }

     @include desktop-xl {
        min-height: 338px;
     }

     @include desktop-xxl {
        min-height: 375px;
     }

    .fixed-width {
        display: flex;
        justify-content: space-between;
        padding: 0;

        @include mobile {
            flex-direction: column;
        }
    }

    .half-width-banner {
        .banner-img {
            a {
                @include mobile {
                    width: 100%;
                }
            }
        }
    }
}
